import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Terms=()=>{
        return (<div className="page">
        <div className="page__top">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="page__h1">Warunki wymiany</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="page__content">
                <p>Przed skorzystaniem z usług Netex24 Użytkownik zobowiązany jest do zapoznania się w całości z Regulaminem serwisu Netex24. Korzystanie z usług Netex24 jest możliwe tylko wtedy, gdy Użytkownik zaakceptuje wszystkie warunki umowy.</p>
                <p>Warunki korzystania z usług Netex24</p>
                <p>1. Terminy i definicje</p>
                <p>1.1. Netex24 
                </p><p>1.2. Użytkownikiem jest każda osoba fizyczna, która zgadza się na wszystkie warunki oferowane przez Serwis i przystąpiła do niniejszej umowy. Użytkownik i Serwis zwani są łącznie "Stronami".</p>
                <p>1.3. Wniosek jest informacją złożoną przez Użytkownika za pośrednictwem Serwisu drogą elektroniczną, wskazującą na zamiar skorzystania z usług Serwisu na warunkach oferowanych przez Serwis i określonych w parametrach wniosku.</p>
                <p>1.4. Wymiana - transakcje polegające na przekazaniu waluty cyfrowej od jednego posiadacza do drugiego przy użyciu gotówki.</p>
                <p>1.5. Kurs walutowy to stosunek wartości dwóch wymienianych walut.</p>
                <p>1.6. Umowa jest umową pomiędzy Stronami o obrót walutami cyfrowymi zawartą na warunkach określonych w Serwisie.</p>
                <p>2. Przedmiot umowy i sposób jej wejścia w życie</p>
                <p>2.1. Przedmiotem niniejszej umowy są usługi obrotu walutami cyfrowymi świadczone na zlecenie Użytkownika, a także inne usługi opisane na stronie internetowej Serwisu.</p>
                <p>2.2. Umowę uważa się za zaakceptowaną z chwilą złożenia przez Użytkownika Wniosku, który stanowi integralną część niniejszej Umowy. Każde zgłoszenie przez Użytkownika stanowi nową Umowę i na nowych warunkach w momencie zgłoszenia.</p>
                <p>2.3. Niniejsza umowa wchodzi w życie z chwilą zakończenia formowania Aplikacji przez Użytkownika i nadania indywidualnego numeru aplikacji.</p>
                <p>2.4. Data i godzina oraz parametry warunków Zamówienia są zapisywane automatycznie przez Serwis w momencie finalizacji Zamówienia i nadania indywidualnego numeru Zamówienia.</p>
                <p>2.5. Niniejsza umowa ulega rozwiązaniu z dniem otrzymania przez Użytkownika środków finansowych lub walut cyfrowych w wysokości określonej w parametrach Aplikacji Użytkownika lub z dniem anulowania Aplikacji.</p>
                <p>2.6. Serwis zastrzega sobie prawo do jednostronnego wprowadzania zmian w niniejszej Umowie bez powiadamiania Użytkownika, ale pod warunkiem opublikowania aktualnej wersji Umowy na tej stronie.</p>
                <p>3. Warunki korzystania z usług</p>
                <p>3.1. Z serwisu Netex24 mogą korzystać tylko ci Użytkownicy, którzy zapoznali się i zaakceptowali regulamin serwisu.</p>
                <p>3.2 Serwis automatycznie ustala kurs walutowy i publikuje go na stronie internetowej Serwisu.</p>
                <p>3.3 Serwis ma prawo do jednostronnej zmiany kursów walut w każdym czasie i powiadomienia o tym Użytkowników Serwisu poprzez zamieszczenie aktualnej informacji na stronie internetowej Serwisu.</p>
                <p>3.4 Ustalanie kursu walutowego i procedura zmiany kursu w aplikacji:</p>
                <ul>
                  <li>Kurs jest stały przez 10 minut od momentu wyboru kierunku wymiany, po tym czasie automatycznie zostanie zaproponowany nowy kurs;</li>
                  <li>Jeśli użytkownik zdążył złożyć wniosek w ciągu 10 minut, to stawka ta zostanie przedłużona o kolejne 15 minut na dokonanie płatności za wniosek. Jeżeli użytkownik nie zdąży dokonać płatności i zmienić statusu zapytania na opłacone poprzez kliknięcie przycisku "Zapłaciłem", po upływie czasu automatycznie zostanie zaproponowany nowy kurs;</li>
                  <li>Serwis zastrzega sobie prawo do jednostronnej zmiany kursu, jeżeli od momentu pojawienia się transakcji w sieci walut cyfrowych upłynęło więcej niż 8 godzin i nie ma 2 potwierdzenia transakcji z sieci walut cyfrowych;</li>
                  <li>Serwis zastrzega sobie prawo do jednostronnej zmiany kursu, jeśli zapytanie zawiera błąd z winy Użytkownika, a Użytkownik w ciągu 8 godzin nie podał poprawnych informacji na e-mail Serwisu;</li>
                  <li>Serwis zastrzega sobie prawo do jednostronnej zmiany kursu walutowego, jeżeli pierwotny kurs walutowy zmienił się o więcej niż 1% przed opłaceniem zgłoszenia, a status zgłoszenia zmienia się na opłacony poprzez kliknięcie przycisku "Zapłaciłem".</li>
                </ul>
                <p>3.5. Użytkownik może zarządzać procesem wymiany lub otrzymywać informacje o postępach w realizacji usługi poprzez odpowiedni interfejs użytkownika znajdujący się na stronie internetowej Serwisu.</p>
                <p>3.6. Serwis realizuje wymiany na zlecenia Użytkowników w ścisłej zgodności ze swoją polityką prywatności, a także z zasadami przeciwdziałania praniu brudnych pieniędzy i zapobiegania transakcjom o nielegalnym charakterze.</p>
                <p>3.7. Każda zrealizowana transakcja przeprowadzona przez Serwis na zlecenie Użytkownika nie może być anulowana przez Użytkownika po jej zakończeniu - przesłaniu przez Serwis należnych Użytkownikowi pieniędzy lub walut cyfrowych na wcześniej przyjętych warunkach wymiany.</p>
                <p>3.8. W przypadku nieotrzymania od Użytkownika środków finansowych lub walut cyfrowych w ciągu jednej godziny od utworzenia przez Użytkownika aplikacji, Umowę uważa się za jednostronnie rozwiązaną przez Serwis jako niewprowadzoną w życie.</p>
                <p>3.9. W przypadku rozwiązania umowy środki pieniężne lub waluty cyfrowe otrzymane po upływie ww. terminu zostaną na wniosek Użytkownika zwrócone na konto Użytkownika, z którego środki te zostały otrzymane. W przypadku dokonywania zwrotów, wszelkie opłaty prowizyjne za przekazanie środków lub walut cyfrowych są pokrywane z otrzymanych środków na koszt Użytkownika. Serwis nie ponosi odpowiedzialności za ewentualne opóźnienia w zwrotach, jeśli nie wynikają one z winy Serwisu.</p>
                <p>3.10. W przypadku, gdy kwota otrzymanych środków lub walut cyfrowych różni się od kwoty podanej przez Użytkownika, Serwis może jednostronnie rozwiązać umowę poprzez odmowę realizacji zlecenia i zwrócić otrzymane środki na konto Użytkownika, z którego otrzymano środki lub przeliczyć kwotę po kursie ustalonym w zleceniu. W przypadku dokonywania zwrotów, wszelkie opłaty prowizyjne za przekazanie środków lub walut cyfrowych są pokrywane z otrzymanych środków na koszt Użytkownika. Serwis nie ponosi odpowiedzialności za ewentualne opóźnienia w zwrotach, jeśli nie wynikają one z winy Serwisu.</p>
                <p>3.11. Jeżeli Serwis nie przekaże środków lub walut cyfrowych na dane wskazane przez Użytkownika w ciągu 72 godzin od otrzymania płatności od Użytkownika, w przypadku braku przyczyn zablokowania środków lub walut cyfrowych otrzymanych za pośrednictwem Aplikacji Użytkownika od Serwisu, Użytkownik może żądać rozwiązania umowy poprzez anulowanie swojej aplikacji i zwrot środków lub walut cyfrowych w całości.</p>
                <p>3.12. W przypadku anulowania wniosku, zwrot środków lub walut cyfrowych dokonywany jest przez Serwis na dane nadawcy w ciągu 3 dni roboczych od otrzymania wniosku o anulowanie. Serwis nie ponosi odpowiedzialności za ewentualne opóźnienia w zwrotach, jeśli nie wynikają one z winy Serwisu.</p>
                <p>3.13.&nbsp;Jeśli kurs wymiany wzrośnie o więcej niż 1%, wszystkie środki nieodebrane przez więcej niż jeden dzień zostaną wymienione lub zwrócone po kursie wymiany obowiązującym w momencie tworzenia wniosku.</p>
                <p>3.14. Serwis ma prawo angażować wykonawców zewnętrznych do realizacji swoich zobowiązań.</p>
                <p>3.15. Serwis ma prawo do anulowania zgłoszenia i zwrotu środków lub walut cyfrowych wpłaconych przez Użytkownika wraz ze zwrotem opłat na rzecz Użytkownika bez podania przyczyny.</p>
                <p>3.16. Serwis ma prawo odmówić dalszej obsługi użytkownika bez podania przyczyny.</p>
                <p>3.17. Serwis ma prawo wstrzymać wymianę w celu identyfikacji nadawcy przelewu. Jeśli identyfikacja nie powiedzie się, wniosek zostanie anulowany. W przypadku zwrotu pieniędzy wszelkie opłaty prowizyjne za przekazanie środków lub walut cyfrowych ponoszone są z otrzymanych środków na koszt Użytkownika. Serwis nie ponosi odpowiedzialności za ewentualne opóźnienia w zwrotach, jeśli nie wynikają one z winy Serwisu.</p>
                <p>3.18. Korzystając z usług Serwisu, Użytkownik potwierdza, że legalnie posiada i zarządza wykorzystywanymi środkami finansowymi i walutami cyfrowymi. Użytkownik gwarantuje, że nie jest zaangażowany w:</p>
                <ul>
                  <li>operacje prania pieniędzy;</li>
                  <li>dochodów z handlu narkotykami;</li>
                  <li>dochody z działalności przestępczej i/lub terrorystycznej;</li>
                  <li>uzyskiwanie dochodów z handlu z krajami, z którymi handel jest zakazany przez organizacje międzynarodowe;</li>
                  <li>otrzymywanie dochodów z jakiejkolwiek innej działalności niezgodnej z prawem.</li>
                </ul>
                <p>3.19. Gdy Użytkownik wysyła waluty cyfrowe, Serwis przystąpi do realizacji swoich zobowiązań w zakresie wymiany dopiero po dwóch potwierdzeniach sieci walut cyfrowych.<br />
                  <br />
                  3.20.&nbsp;Jeśli środki nie mogą zostać wysłane na dane podane przez klienta z przyczyn niezależnych od serwisu lub jeśli klient popełnił błąd w danych, serwis ma prawo poprosić klienta o przesłanie poprawnych danych na czat wsparcia, przesłanie weryfikacji karty lub danych do zwrotu pieniędzy.</p>
                <p>4. Polityka prywatności</p>
                <p>4.1. W celu realizacji operacji Serwis przyjmuje od Użytkownika dane osobowe, które Serwis zobowiązuje się przechowywać w formie zaszyfrowanej, nie upubliczniać, nie przekazywać osobom trzecim, za wyjątkiem sytuacji opisanych w paragrafie 4.4 i paragrafie 5.5 niniejszej Umowy.</p>
                <p>4.2. Wszystkie transakcje z Zamówieniami, a także przekazywanie danych osobowych przez Użytkownika do Serwisu odbywa się za pośrednictwem szyfrowanego kanału SSL o długości klucza 256 bitów.</p>
                <p>4.3. Serwis ma prawo do samodzielnego zbierania dodatkowych danych o Użytkowniku, w razie potrzeby, wszelkimi dostępnymi środkami. Wszystkie informacje zebrane w wyniku takich działań nie będą upubliczniane ani ujawniane osobom trzecim, z wyjątkiem przypadków opisanych w punktach 4.4 i 5.5 niniejszej umowy.</p>
                <p>4.4. Serwis ma prawo udostępnić dane osobowe Użytkownika oraz szczegóły jego transakcji na oficjalne żądanie organów ścigania, sądów, a także z własnej inicjatywy w celu ochrony własnych praw.</p>
                <p>4.5. Wszystkie zebrane dane o Użytkowniku, a także szczegóły jego transakcji, przechowywane są w bazie danych Serwisu przez pięć lat.</p>
                <p>5. Polityka przeciwdziałania praniu pieniędzy i nielegalnym transakcjom</p>
                <p>5.1. Zgodnie z prawem międzynarodowym, Serwis przestrzega szeregu zasad i wdraża szereg procedur mających na celu uniemożliwienie wykorzystania Serwisu w celu prania pieniędzy i innych transakcji o nielegalnym charakterze.</p>
                <p>5.2. Aby zapobiec transakcjom o charakterze nielegalnym, Serwis stawia określone wymagania dla wszystkich Aplikacji tworzonych przez Użytkownika:</p>
                <ul>
                  <li>Przekazywanie danych osobom trzecim jest surowo zabronione;</li>
                  <li>Wszystkie dane kontaktowe wprowadzone przez Użytkownika w Aplikacji, jak również inne dane osobowe przekazane przez Użytkownika do Serwisu muszą być aktualne i w pełni zgodne z prawdą;</li>
                  <li>Surowo zabronione jest tworzenie przez Użytkownika Aplikacji z wykorzystaniem anonimowych serwerów proxy lub innych anonimowych połączeń z Internetem.</li>
                </ul>
                <p>5.3. Serwis zastrzega sobie prawo do wstrzymania wszystkich bieżących zgłoszeń Użytkownika do czasu otrzymania od Użytkownika kopii dokumentów tożsamości oraz innych informacji niezbędnych do weryfikacji transakcji w następujących przypadkach:</p>
                <ul>
                  <li>W przypadku stwierdzenia naruszenia któregokolwiek z wymogów określonych w punkcie 5.2 niniejszego dokumentu, Umowa ulega rozwiązaniu;</li>
                  <li>Gdy aplikacja Użytkownika zostanie zatrzymana przez System Zapobiegania Nielegalnym Transakcjom;</li>
                  <li>Jeżeli Serwis ma uzasadnione podejrzenie, że użytkownik próbuje korzystać z usług Serwisu w celu przeprowadzenia nielegalnych transakcji.</li>
                </ul>
                <p>W zamian Użytkownik zobowiązuje się do dostarczenia żądanego dokumentu w terminie 7 dni roboczych od otrzymania żądania jego dostarczenia lub do złożenia wniosku o jego anulowanie.</p>
                <p>5.4. W przypadku odmowy dostarczenia przez Użytkownika wymaganych dokumentów, Serwis zastrzega sobie prawo do odmowy świadczenia usługi zgodnie z pkt 3.15 i podjęcia działań opisanych w pkt 3.14 niniejszej Umowy.</p>
                <p>5.5. Serwis zastrzega sobie prawo do odmowy dalszej obsługi i podjęcia działań opisanych w punkcie 3.14 niniejszej Umowy, po czym wszystkie dane Użytkownika dostępne dla Serwisu, jak również wszystkie dostępne informacje o transakcjach Użytkownika zostaną przekazane organom ścigania w przypadkach:</p>
                <p>5.5.1. W przypadku anulowania aplikacji, zwrot środków lub walut cyfrowych dokonywany jest przez Serwis na dane nadawcy w ciągu 72 godzin od otrzymania wniosku o anulowanie. Serwis nie ponosi odpowiedzialności za ewentualne opóźnienia w zwrotach, jeśli nie wynikają one z winy Serwisu. Opłata u źródła 12,6%</p>
                <ul>
                  <li>Wykrywanie transakcji przeznaczonych na pranie pieniędzy, finansowanie organizacji terrorystycznych, oszustwa wszelkiego rodzaju, jak również transakcji przeznaczonych na wszelkie inne nielegalne lub nieprawidłowe transakcje;</li>
                  <li>Jeżeli Serwis ma uzasadnione podejrzenie, że dokument podany przez Użytkownika w celu identyfikacji Użytkownika jest fałszywy lub nieważny;</li>
                  <li>Otrzymanie informacji od uprawnionych organów o nieuprawnionym posiadaniu przez Użytkownika środków pieniężnych lub walut cyfrowych lub innych informacji uniemożliwiających świadczenie usług przez Serwis na rzecz Użytkownika;</li>
                  <li>Identyfikacja wszelkich działań lub prób działań Użytkownika mających na celu spowodowanie jakiegokolwiek negatywnego wpływu na sprzęt i oprogramowanie Serwisu;</li>
                  <li>Identyfikacja wszelkich działań lub prób działań Użytkownika mających na celu kradzież baz danych oraz innych dóbr materialnych i niematerialnych Serwisu;</li>
                  <li>Identyfikowanie wszelkich działań lub prób działań podejmowanych przez Użytkownika, które mogłyby spowodować jakiekolwiek szkody fizyczne, materialne lub niematerialne w Serwisie.</li>
                  <li>W przypadku otrzymania środków z innej karty niż ta, która została zweryfikowana, Serwis wstrzyma wymianę. Środki zostaną zwrócone nadawcy w ciągu 24 godzin od zgłoszenia i pod warunkiem obowiązkowej weryfikacji karty, z której wpłynęły środki. Przy zwrocie środków z otrzymanych środków zostaną potrącone na koszt Użytkownika wszelkie opłaty prowizyjne za przekazanie środków lub walut cyfrowych.</li>
                </ul>
                <p>5.6. Serwis zastrzega sobie prawo do wstrzymania wszystkich bieżących zgłoszeń Użytkownika do czasu otrzymania od Użytkownika kopii dokumentów lub innych informacji potwierdzających fakt zapłaty.</p>
                <p>6. Ograniczenie odpowiedzialności</p>
                <p>6.1. Użytkownik gwarantuje, że nie jest obywatelem lub rezydentem podatkowym Stanów Zjednoczonych Ameryki, Korei Północnej lub Iranu.</p>
                <p>6.2. Z serwisu mogą korzystać wyłącznie osoby pełnoletnie.</p>
                <p>6.3. Użytkownik wyraża zgodę na to, że odpowiedzialność Serwisu jest ograniczona do środków otrzymanych od Użytkownika na realizację przedmiotu niniejszej umowy, że Serwis nie udziela Użytkownikowi żadnych dodatkowych gwarancji ani nie ponosi odpowiedzialności wobec Serwisu.</p>
                <p>6.4. Serwis dołoży wszelkich starań, ale nie gwarantuje, że jego usługi będą dostępne 24 godziny na dobę, każdego dnia. Serwis nie ponosi odpowiedzialności za jakiekolwiek straty, utracone zyski lub inne koszty poniesione przez Użytkownika w wyniku niemożności dostępu do strony internetowej i usług Serwisu.</p>
                <p>6.5. Serwis nie ponosi odpowiedzialności za jakiekolwiek straty, utracone zyski lub inne koszty poniesione przez Użytkownika w wyniku opóźnień, błędów lub niepowodzeń w realizacji płatności bankowych lub przelewów elektronicznych.</p>
                <p>6.6. Serwis nie ponosi odpowiedzialności za jakiekolwiek straty, utracone zyski lub inne wydatki Użytkownika spowodowane fałszywymi oczekiwaniami Użytkownika w zakresie kursu walutowego Serwisu, opłacalności transakcji lub innych subiektywnych czynników.</p>
                <p>6.7. Jeżeli Użytkownik podał błędne informacje w danych beneficjenta, Serwis nie ponosi odpowiedzialności za jakiekolwiek negatywne konsekwencje lub szkody wynikające z tego błędu.</p>
                <p>6.8. Użytkownik zobowiązuje się nie manipulować przepływami komunikacyjnymi związanymi z funkcjonowaniem Serwisu, nie ingerować w jego oprogramowanie i/lub sprzęt oraz nie wywierać żadnego innego wpływu, który mógłby zakłócić normalne funkcjonowanie Serwisu, rozumiejąc, że takie działania będą ścigane w pełnym zakresie prawa.</p>
                <p>6.9. Zarówno Użytkownik jak i Serwis nie ponoszą wzajemnej odpowiedzialności za opóźnienia lub niewykonanie swoich zobowiązań wynikające ze zdarzeń o charakterze siły wyższej, w tym klęsk żywiołowych, pożaru, powodzi, ataków terrorystycznych, zmiany rządu, niepokojów społecznych, a także awarii elektronicznych systemów rozliczeniowych, systemów zasilania, sieci komunikacyjnych i dostawców usług internetowych.</p>
                <p>6.10. Systemy płatności elektronicznych lub instytucje finansowe ponoszą wyłączną odpowiedzialność za środki powierzone im przez Użytkownika. Serwis nie może być stroną umowy pomiędzy Systemem Płatności lub instytucją finansową a Użytkownikiem.</p>
                <p>7. Procedury przyjmowania roszczeń i rozstrzygania sporów</p>
                <p>7.1. Spory i nieporozumienia wynikające ze świadczenia usług przez Serwis na rzecz Użytkownika będą rozstrzygane w drodze negocjacji pomiędzy Użytkownikiem a administracją Serwisu, w oparciu o postanowienia niniejszej umowy.</p>
                <p>7.2. Wszelkie reklamacje wynikające z niniejszej Umowy należy przesyłać drogą elektroniczną na adres e-mail podany na stronie internetowej Serwisu.</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>8. Przepisy końcowe</span></p>
                <p>8.1. Serwis ma prawo do wysyłania na podany przez Użytkownika adres e-mail informacji o stanie procesu wymiany, a także innych informacji związanych z wymianą.</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>8.2.Informacje zawarte na tej stronie, w tym grafiki, teksty, kody programistyczne itp. stanowią własność Serwisu i są chronione prawem autorskim.</span></p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>8.3. Użytkownik potwierdza, że zapoznał się ze wszystkimi postanowieniami niniejszej Umowy i bezwarunkowo je akceptuje, w przeciwnym razie Użytkownik nie może korzystać z Serwisu.</span></p>
                <p>&nbsp;</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>UMOWA DOTYCZĄCA PLIKÓW COOKIE</span></p>
                <p>Niniejsza umowa dotyczy wykorzystania przez Stronę informacji otrzymanych od użytkowników Strony. Zawiera również informacje o plikach cookie, wykorzystaniu plików cookie przez Witrynę oraz o tym, jak można zrezygnować z plików cookie.</p>
                <p>Plik cookie to mały plik tekstowy umieszczany przez stronę internetową na komputerze osobistym, telefonie lub innym urządzeniu użytkownika, zawierający informacje o sposobie poruszania się po stronie internetowej. Pliki cookie przechowują informacje o preferencjach użytkownika w Internecie.</p>
                <p>Pliki cookies wykorzystywane są w różnych celach, m.in.</p>
                <ul>
                  <li>Analizowania informacji o wizytach użytkowników w celu ulepszenia Strony.</li>
                  <li>Dostarczanie wiadomości i treści tworzonych przez nas i osoby trzecie na tej stronie i innych stronach internetowych z myślą o interesach użytkownika.</li>
                  <li>Pomagaj użytkownikowi w uzyskaniu niezbędnych informacji.</li>
                  <li>Określenie liczby odwiedzających i sposobu korzystania z naszej strony - w celu poprawy efektywności strony i najlepszego zrozumienia zainteresowań swoich odbiorców.</li>
                </ul>
                <p>Zanim strona internetowa ustawi pliki cookie na Twoim komputerze, zobaczysz wyskakujące okienko z prośbą o wyrażenie zgody na ustawienie tych plików. Wyrażając zgodę na ustawienie naszych plików cookie, umożliwiasz nam zapewnienie Ci najlepszych możliwych doświadczeń i usług za pośrednictwem naszej strony internetowej. Jeśli użytkownik chce odmówić zgody na umieszczanie plików cookie, niektóre funkcje strony internetowej mogą nie działać w pełni lub zgodnie z przeznaczeniem.</p>
                <p>Żaden z plików cookies ustawionych przez stronę internetową w żaden sposób nie narusza Twojej prywatności. Stosowane przez nas pliki cookies nie przechowują osobistych i poufnych informacji identyfikujących użytkownika, takich jak adres, hasło, dane karty debetowej lub kredytowej itp.</p>
                <p>Jeśli chcesz uniknąć korzystania z plików cookie na tej stronie, możesz w każdej chwili wyłączyć przechowywanie plików cookie w swojej przeglądarce, a następnie usunąć pliki cookie przechowywane w przeglądarce związane z korzystaniem z naszej strony internetowej.</p>
                <p>Uwaga: w tym przypadku wyświetlanie stron i prowadzenie strony internetowej będzie ograniczone.</p>
                <p>Nie ponosimy odpowiedzialności za treść, gromadzenie i przetwarzanie informacji przez strony internetowe osób trzecich, w tym strony, które mogą być powiązane z naszą witryną. Przed przesłaniem informacji należy zapoznać się z polityką prywatności odwiedzanych zasobów.</p>
                <p>&nbsp;</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>Polityka przetwarzania danych osobowych użytkowników strony internetowej</span></p>
                <p>&nbsp;</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>Przepisy ogólne</span></p>
                <p>&nbsp;</p>
                <p>1.1. Niniejszy dokument określa politykę serwisu Netex24 (dalej - Operator) w zakresie przetwarzania danych osobowych użytkowników serwisu oraz określa system podstawowych zasad obowiązujących przy przetwarzaniu danych osobowych.</p>
                <p>1.2 Polityka przetwarzania danych osobowych użytkowników strony internetowej (zwana dalej "Polityką") została opracowana w celu zachowania zgodności z ustawodawstwem Federacji Rosyjskiej zawierającym dane osobowe oraz w celu identyfikacji użytkowników, którzy znajdują się na stronie internetowej.</p>
                <p>Polityka ma zastosowanie do wszystkich danych osobowych, które będą przetwarzane przez operatora.</p>
                <p>1.3 Niniejsza polityka została przygotowana zgodnie z Konstytucją RP, Kodeksem Cywilnym oraz innymi obowiązującymi w Polsce przepisami dotyczącymi ochrony danych osobowych.</p>
                <p>1.4 Polityka określa procedurę przetwarzania danych osobowych użytkowników strony internetowej: działania w zakresie zbierania, systematyzowania, gromadzenia, przechowywania, wyjaśniania (aktualizacji, modyfikacji), niszczenia danych osobowych.</p>
                <p>1.5 Niniejsza polityka określa ogólne wymagania i zasady obowiązujące pracowników Operatora zaangażowanych w utrzymanie strony internetowej dla wszystkich form nośników zawierających dane osobowe użytkowników strony.</p>
                <p>1.6 Polityka nie odnosi się do bezpieczeństwa danych osobowych zakwalifikowanych jako tajemnica państwowa Federacji Rosyjskiej zgodnie z ustaloną procedurą.</p>
                <p>1.7 Terminy i definicje</p>
                <p>Dane osobowe to wszelkie informacje dotyczące bezpośrednio lub pośrednio zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej (osoby, której dane dotyczą).</p>
                <p>Operator - organ państwowy, organ gminy, osoba prawna lub osoba fizyczna, samodzielnie lub wspólnie z innymi osobami organizująca i (lub) dokonująca przetwarzania danych osobowych, a także określająca cele przetwarzania danych osobowych, skład danych osobowych podlegających przetwarzaniu, czynności (operacje) wykonywane z danymi osobowymi.</p>
                <p>Przetwarzanie danych osobowych oznacza jakiekolwiek działanie (operację) lub zestaw działań (operacji) wykonywanych przy użyciu lub bez użycia środków automatyzacji z danymi osobowymi, w tym zbieranie, utrwalanie, systematyzowanie, gromadzenie, przechowywanie, wyjaśnianie (aktualizowanie, zmienianie), pobieranie, wykorzystywanie, przekazywanie (rozpowszechnianie, udostępnianie), anonimizowanie, blokowanie, usuwanie, niszczenie danych osobowych.</p>
                <p>Użytkownik to osoba korzystająca z Internetu.</p>
                <p>Zautomatyzowane przetwarzanie danych osobowych to przetwarzanie danych osobowych za pomocą technologii komputerowej.</p>
                <p>Udostępnienie danych osobowych to czynność polegająca na ujawnieniu danych osobowych określonej osobie lub określonemu kręgowi osób.</p>
                <p>Blokowanie danych osobowych - czasowe zawieszenie przetwarzania danych osobowych (chyba że przetwarzanie jest niezbędne do wyjaśnienia danych osobowych).</p>
                <p>Zniszczenie danych osobowych - działania skutkujące brakiem możliwości odtworzenia zawartości danych osobowych w systemie informatycznym danych osobowych i/lub skutkujące zniszczeniem materialnych nośników danych osobowych.</p>
                <p>Depersonalizacja danych osobowych to czynność polegająca na uniemożliwieniu, bez użycia dodatkowych informacji, ustalenia tożsamości danych osobowych dotyczących konkretnego podmiotu danych osobowych.</p>
                <p>1.8 Operator zapewnia poufność i bezpieczeństwo danych osobowych podczas ich przetwarzania zgodnie z wymogami prawnymi i nie ujawnia ani nie rozpowszechnia danych osobowych osobom trzecim bez zgody osoby, której dane dotyczą, chyba że prawo federalne stanowi inaczej.</p>
                <p>Zgodnie z wykazem danych osobowych przetwarzanych na stronie internetowej, dane osobowe użytkowników strony internetowej stanowią informacje poufne.</p>
                <p>&nbsp;</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>2. Podstawowe prawa i obowiązki Operatora i Osoby, której dane dotyczą.</span></p>
                <p>&nbsp;</p>
                <p>2.1 Operator ma prawo:</p>
                <p>Odbierać dane osobowe od podmiotów danych osobowych oraz od osób trzecich (osób nie będących podmiotami danych osobowych), przy czym operator wypełnia obowiązki przewidziane prawem</p>
                <p>samodzielnie ustalić skład i listę środków niezbędnych i wystarczających do zapewnienia wypełnienia obowiązków wynikających z ustawy o danych osobowych i przyjętych zgodnie z nią rozporządzeń, o ile ustawa o danych osobowych lub inne ustawy federalne nie stanowią inaczej;</p>
                <p>W przypadku wycofania przez osobę, której dane dotyczą, zgody na przetwarzanie danych osobowych, operator ma prawo do dalszego przetwarzania danych osobowych bez zgody osoby, której dane dotyczą, z przyczyn określonych w ustawie o danych osobowych.</p>
                <p>2.2 Operator jest zobowiązany do:</p>
                <p>zorganizować przetwarzanie danych osobowych zgodnie z wymogami ustawy o danych osobowych;</p>
                <p>odpowiadać na wnioski i zapytania osób, których dane osobowe dotyczą oraz ich przedstawicieli prawnych zgodnie z wymogami ustawy o danych osobowych.</p>
                <p>2.3. Osoba, której dane dotyczą ma prawo do</p>
                <p>Otrzymywać informacje związane z przetwarzaniem jego danych osobowych, z wyjątkiem przypadków przewidzianych w ustawach federalnych. Informacje przekazywane są przez Operatora osobie, której dane osobowe dotyczą, w przystępnej formie i nie zawierają danych osobowych dotyczących innych osób, których dane osobowe dotyczą, chyba że istnieją uzasadnione podstawy do ujawnienia takich danych osobowych. Wykaz informacji oraz tryb ich uzyskania określa ustawa o danych osobowych;</p>
                <p>zażądać od operatora wyjaśnienia, zablokowania lub zniszczenia danych osobowych, jeśli dane osobowe są niekompletne, nieaktualne, niedokładne, uzyskane niezgodnie z prawem lub niepotrzebne do osiągnięcia określonego celu przetwarzania;</p>
                <p>Inne prawa przewidziane przez prawo</p>
                <p>3. Cele gromadzenia danych osobowych</p>
                <p>3.1 Przetwarzanie danych osobowych ogranicza się do osiągnięcia konkretnych, z góry określonych i zgodnych z prawem celów. Niedopuszczalne jest przetwarzanie danych osobowych niezgodne z celami zbierania danych osobowych.</p>
                <p>3.2 Przetwarzane mogą być tylko te dane osobowe, które spełniają cele, dla których są przetwarzane.</p>
                <p>3.3 Przetwarzanie danych osobowych użytkowników strony internetowej odbywa się wyłącznie w celu umożliwienia użytkownikowi interakcji ze stroną internetową.</p>
                <p>3.4 Informacje stanowiące dane osobowe na stronie internetowej to wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej (podmiotu danych osobowych) na podstawie takich informacji.</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>4. Объем обрабатываемых персональных данных.</span></p>
                <p>&nbsp;</p>
                <p>4.1. Treść i zakres przetwarzanych danych osobowych użytkowników strony internetowej odpowiada wskazanym celom przetwarzania określonym w punkcie 3 niniejszej Polityki. Przetwarzane dane osobowe nie mogą być nadmierne w stosunku do podanych celów przetwarzania.</p>
                <p>4.2. Operator może przetwarzać następujące dane osobowe użytkowników:</p>
                <ul>
                  <li>źródło wejścia na stronę (strony) oraz informacje o wyszukiwaniu lub zapytaniach reklamowych;</li>
                  <li>dane urządzenia użytkownika (w tym rozdzielczość, wersja i inne atrybuty charakteryzujące urządzenie użytkownika);</li>
                  <li>kliknięcia użytkowników, odsłony stron, wypełnienia pól, wyświetlenia i odsłony banerów i filmów;</li>
                  <li>dane charakteryzujące segmenty odbiorców;</li>
                  <li>parametry sesji;</li>
                  <li>dane dotyczące czasu wizyty;</li>
                  <li>identyfikator użytkownika zapisany w pliku cookie;</li>
                  <li>nazwisko;</li>
                  <li>nazwa;</li>
                  <li>patronimiczny;</li>
                  <li>numer telefonu kontaktowego;</li>
                  <li>adres e-mail;</li>
                </ul>
                <p>4.3 Operator nie przetwarza szczególnych kategorii danych osobowych dotyczących rasy, narodowości, poglądów politycznych, przekonań religijnych lub filozoficznych, stanu zdrowia, życia intymnego, z wyjątkiem przypadków przewidzianych przez polskie prawo</p>
                <p>4.4 Operator nie przetwarza biometrycznych danych osobowych.</p>
                <p>4.5 Operator nie przekazuje danych osobowych za granicę.</p>
                <p>&nbsp;</p>
                <p><span style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'roboto', fontSize: '14px'}}>5. Procedura i warunki przetwarzania danych osobowych.</span></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>5.1 Przetwarzanie danych osobowych będzie realizowane przez Operatora zgodnie z wymogami ustawodawstwa Federacji Rosyjskiej.</p>
                <p>5.2 Przetwarzanie danych osobowych będzie realizowane przez Operatora zgodnie z wymogami ustawodawstwa Federacji Rosyjskiej.</p>
                <p>5.3 Operator dokonuje zarówno zautomatyzowanego jak i niezautomatyzowanego przetwarzania danych osobowych.</p>
                <p>5.4 Do przetwarzania danych osobowych dopuszczeni są pracownicy Operatora, do których obowiązków służbowych należy przetwarzanie danych osobowych.</p>
                <p>5.5 Dane osobowe nie mogą być ujawniane i udostępniane osobom trzecim lub rozpowszechniane bez zgody osoby, której dane dotyczą, chyba że prawo federalne stanowi inaczej.</p>
                <p>5.6 Przekazywanie danych osobowych organom dochodzenia i śledztwa, Federalnej Służbie Podatkowej, Funduszowi Emerytalnemu Federacji Rosyjskiej, Funduszowi Ubezpieczeń Społecznych oraz innym uprawnionym organom wykonawczym i organizacjom odbywa się zgodnie z wymogami prawa polskiego.</p>
                <p>5.7 Operator podejmuje niezbędne środki prawne, organizacyjne i techniczne w celu ochrony danych osobowych przed nieuprawnionym lub przypadkowym dostępem, zniszczeniem, modyfikacją, zablokowaniem, rozpowszechnieniem i innymi nieuprawnionymi działaniami, w tym</p>
                <ul>
                  <li>identyfikuje zagrożenia dla bezpieczeństwa przetwarzania danych osobowych;</li>
                  <li>Uchwala akty prawa miejscowego i inne dokumenty regulujące stosunki w zakresie przetwarzania i ochrony danych osobowych;</li>
                  <li>tworzy niezbędne warunki do postępowania z danymi osobowymi;</li>
                  <li>organizuje ewidencję dokumentów zawierających dane osobowe;</li>
                  <li>organizuje pracę z systemami informatycznymi, w których przetwarzane są dane osobowe;</li>
                  <li>Przechowywać dane osobowe w sposób zapewniający ich bezpieczeństwo i uniemożliwiający dostęp do nich osobom nieupoważnionym.</li>
                </ul>
                <p>5.8 Operator przechowuje dane osobowe w formie umożliwiającej identyfikację osoby, której dane dotyczą, nie dłużej niż wymagają tego cele przetwarzania danych osobowych, chyba że okres przechowywania danych osobowych wynika z przepisów prawa lub umowy.</p>
                <p>5.9 Przy zbieraniu danych osobowych, w tym za pomocą sieci informacyjno-telekomunikacyjnej Internet, operator zapewnia rejestrowanie, systematyzowanie, gromadzenie, przechowywanie, wyjaśnianie (aktualizowanie, zmienianie), wydobywanie danych osobowych obywateli za pomocą baz danych znajdujących się na terenie Federacji Rosyjskiej, z wyjątkiem przypadków określonych w ustawie o danych osobowych.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="banner">
                <div className="banner-title">
                  24/7 JAKOŚCIOWA I SZYBKA USŁUGA WYMIANY WALUT  </div>
                <Link className="form-btn banner-link" to="/">Rozpocząć wymianę</Link></div>
            </div>
          </div>
        </div>
      </div>
      )
}

export default Terms