import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import mobxStore from "./mobx/mobxStore";
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref) {
  
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const {setCurrencyFromOpened,setCurrencyToOpened,setDropDownOpened,variables}=mobxStore;
      
      if (ref.current && !ref.current.contains(event.target)) {
        console.log();
        if(ref.current.children[0].id==="currency-to"&&variables.currencyToOpened){
          setCurrencyToOpened(false)
        }
        else if(ref.current.children[0].id==="currency-from"&&variables.currencyFromOpened){
          setCurrencyFromOpened(false);
        }
        else if(ref.current.children[0].id==="header"&&variables.dropDownOpened){
          setDropDownOpened(false);
        }
       // alert("You clicked outside of me!");

      //  if(variables.currencyFromOpened){

      //   setTimeout( setCurrencyFromOpened(false),200)
      //  }
      //  else if(variables.currencyToOpened){
      //  setTimeout( ;
      //  }
      }
    }
    
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
};

export default OutsideAlerter;
