
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";



import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, payOrder } from "../Redux/Actions/orderActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { telegramSupportLink } from "../App";
function useInterval(callback, delay) {
    const intervalRef = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === "number") {
            intervalRef.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalRef.current);
        }
    }, [delay]);
    return intervalRef;
}
const OrderScreen = ({ match }) => {
   
    
    const [remainingTime, setRemainingTime] = useState(3600); 
    
    const orderId = match.params.id;
    const dispatch = useDispatch();
    
    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading } = orderDetails;
    const orderPay = useSelector((state) => state.orderPay);
    const { successPay } = orderPay;
    const [walletCopied, setWalletCopied] = useState(false);
    const [amountCopied, setAmountCopied] = useState(false);
    useEffect(() => {
        if (successPay) {
            dispatch(getOrderDetails(orderId));
        }
    }, [dispatch, orderId, successPay]);
    useEffect(() => {
        if (!order) {
            dispatch(getOrderDetails(orderId));
        }
    }, [dispatch, orderId, order]);
   

    const walletCopiedHandler = () => {
        setWalletCopied(true);
       
        setTimeout(() => {
            setWalletCopied(false);
        }, 2000);
    };
    const amountCopiedHandler = () => {
        setAmountCopied(true);

        setTimeout(() => {
            setAmountCopied(false);
        }, 2000);
    };
    const iPaid = () => {
        dispatch(payOrder(orderId));
    };

        
    useEffect(() => {
        if (!loading  ) {
          // Если данные получены и загрузка завершена, вычисляем оставшееся время
          const startTime = new Date(order.createdAt).getTime();
         
          const currentTime = new Date().getTime();
          const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
          const remainingTime = Math.max(3600 - elapsedSeconds, 0); // 1 hour in seconds
          setRemainingTime(remainingTime);
        }
      }, [dispatch,loading, orderDetails,order]);
    
      useInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime(remainingTime - 1);
        }
      }, 1000); // Update every second


      const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      if(!loading &&order.promisedPayment){
        return(<React.Fragment>
            <div className="backdrop"></div>
            <div className="container">
  <div className="row">
    <div className="steps__form steps__form2">
      <h2 id="step2-h2" style={{display: 'none'}}>Opłata zamówienia №16045894</h2>
      <h2 id="step3-h2" className="disable" style={{display: 'block'}}>Sprawdzenie transakcji №16045894</h2>
      <h2 id="step4-h2" className="disable">Błąd zamówienia №16045894</h2>
      <ul className="status-line">
        <li className="status">
          <svg width={20} height={7} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.5L4.5 6L9.5 1" stroke="#48423B" strokeLinecap="round" />
          </svg>
          Weryfikacja
        </li>
        <li className="status status2">
          <svg width={20} height={7} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.5L4.5 6L9.5 1" stroke="#48423B" strokeLinecap="round" />
          </svg>
          Opłata
        </li>
        <li className="status status3 active">
          Sprawdzanie </li>
        <li className="status">
          Przelew na konto </li>
        <li className="status">
          Gotowe </li>
      </ul>
      <div className="step2-body" style={{display: 'none'}}>
        <div className="row">
          <div className="col-md-6">
            <div className="step2-input">
              <div className="step2-input-text">
                Portfel kryptowaluty:
                <br />
                <input type="hidden" defaultValue="114591.19" readOnly id="to_summ" />
                <input type="text" defaultValue="bc1qrenk4wrafttxnac9a9we0x0x8472my8s3azh7w" readOnly name="Orders[account]" id="account" />
                <input type="hidden" defaultValue="fffffffffff@fffffffffff" readOnly name="Orders[contact]" />
                <input type="hidden" defaultValue={1} readOnly id="from_summ" />
                <input type="hidden" defaultValue="Bitcoin" readOnly name="Orders[currency_from_name]" />
                <input type="hidden" id="account" defaultValue="ffffffffffff" readOnly name="Orders[fio]" />
              </div>
              <button className="step2-input-btn btn-copy" type="button" data-id="account">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="account-tocopy">
                  <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z" fill="black" />
                </svg>
                <img src="/img/Green_check.svg" alt="copied!" width={24} height={24} id="account-copied" style={{display: 'none'}} />
              </button>
            </div>
            <div className="step2-input">
              <div className="step2-input-text">
                Kwota: <input type="text" id="summ_from" defaultValue={1} style={{width: '132px'}} readOnly />
                Bitcoin                                      </div>
              <button className="step2-input-btn btn-copy2" type="button" data-id="summ_from">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="amount-tocopy">
                  <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z" fill="black" />
                </svg>
                <img src="/img/Green_check.svg" alt="copied!" width={24} height={24} id="amount-copied" style={{display: 'none'}} />
              </button>
            </div>
          </div>
        </div>
        <div className="form-bottom form-bottom-step2">
          <div className="timer-wrap">
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="jss210">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8ZM7.47 9.29L11.55 11.8C11.91 12.02 12.38 11.91 12.6 11.56C12.82 11.2 12.7 10.73 12.34 10.51L8.5 8.2V3.75C8.5 3.34 8.16 3 7.75 3C7.34 3 7 3.34 6.99 3.75V8.44C6.99 8.79 7.17 9.11 7.47 9.29Z" fill="#48423B" />
            </svg>
            Dokonaj płatności w ciągu: <span className="timer">59:55</span>
          </div>
          <button className="form-btn" id="step2-btn">OPŁATA WYKONANA</button>
        </div>
      </div>
      <div className="step-body step3-body disable" style={{display: 'block'}}>
        <div className="spinner">
          <img src="/img/waitorder.svg" alt="" />
        </div>
        <div className="step-text">
          <span>
            Obecnie sprawdzamy twoją płatność </span>
        </div>
      </div>
      <div className="step-body step4-body disable">
        <div className="spinner">
          <img src="/img/wrongorder.svg" alt="" />
        </div>
        <div className="step-text">
          <span>
            Zlecenie nie zostało przetworzone, skontaktuj się z czatem online </span>
        </div>
        <div className="step-note">
        </div>
        <div className="steps-btns">
          <Link className="form-btn" to="/">Nowa wymiana</Link> <a href={telegramSupportLink} className="form-btn form-btn-chat"><img src="/img/icon_chat.svg" alt="" />Napisz do online
            czatu</a>
        </div>
      </div>
    </div>
  </div>
</div>

        </React.Fragment>)
      }


    return (
        <React.Fragment>
            <div className="backdrop"></div>

            <div className="container">
                <div className="row">
                    <div className="steps__form steps__form2">
                        <h2 id="step2-h2">Opłata zamówienia №16045894</h2>
                        <h2 id="step3-h2" className="disable">
                            Sprawdzenie transakcji №16045894
                        </h2>
                        <h2 id="step4-h2" className="disable">
                            Błąd zamówienia №16045894
                        </h2>
                        <ul className="status-line">
                            <li className="status">
                                <svg
                                    width={20}
                                    height={7}
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 2.5L4.5 6L9.5 1"
                                        stroke="#48423B"
                                        strokeLinecap="round"
                                    />
                                </svg>
                                Weryfikacja
                            </li>
                            <li className="status status2 active">
                                <svg
                                    width={20}
                                    height={7}
                                    viewBox="0 0 10 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 2.5L4.5 6L9.5 1"
                                        stroke="#48423B"
                                        strokeLinecap="round"
                                    />
                                </svg>
                                Opłata
                            </li>
                            <li className="status status3 ">Sprawdzanie </li>
                            <li className="status">Przelew na konto </li>
                            <li className="status">Gotowe </li>
                        </ul>
                        <div className="step2-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="step2-input">
                                        <div className="step2-input-text">
                                            Portfel kryptowaluty:
                                            <br />
                                            <input
                                                type="text"
                                                defaultValue={!loading?order.receiverWallet:null}
                                                readOnly
                                                
                                                id="account"
                                            />
                                        </div>
                                        <CopyToClipboard text={!loading?order.receiverWallet:null} onCopy={walletCopiedHandler}>
                                        <button
                                            className="step2-input-btn btn-copy"
                                            type="button"
                                        >
                                            <svg
                                            style={{ display: `${walletCopied?"none":""}` }}
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                id="account-tocopy"
                                            >
                                                <path
                                                    d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <img
                                                src="/img/Green_check.svg"
                                                alt="copied!"
                                                width={24}
                                                height={24}
                                                id="account-copied"
                                                style={{ display: `${walletCopied?"":"none"}` }}
                                            />
                                        </button>
                                        </CopyToClipboard>
                                        
                                    </div>
                                    <div className="step2-input">
                                        <div className="step2-input-text">
                                            Kwota:{" "}
                                            <input
                                                type="text"
                                                id="summ_from"
                                                defaultValue={!loading?order.sendAmount:null}
                                                style={{ width: "132px" }}
                                                readOnly
                                            />
                                            {!loading?order.sendCurr:null}
                                        </div>
                                        <CopyToClipboard text={!loading?order.sendAmount:null} onCopy={amountCopiedHandler}>
                                        <button
                                            className="step2-input-btn btn-copy2"
                                            type="button"
                                            
                                        >

                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                id="amount-tocopy"
                                                style={{display:`${amountCopied?"none":""}`}}
                                            >
                                                <path
                                                    d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5L21 11V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H15ZM14 12H19.5L14 6.5V12Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <img
                                            style={{display:`${amountCopied?"":"none"}`}}
                                                src="/img/Green_check.svg"
                                                alt="copied!"
                                                width={24}
                                                height={24}
                                                id="amount-copied"
                                               
                                            />
                                        </button>
                                        </CopyToClipboard>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="form-bottom form-bottom-step2">
                                <div className="timer-wrap">
                                    <svg
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="jss210"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8ZM7.47 9.29L11.55 11.8C11.91 12.02 12.38 11.91 12.6 11.56C12.82 11.2 12.7 10.73 12.34 10.51L8.5 8.2V3.75C8.5 3.34 8.16 3 7.75 3C7.34 3 7 3.34 6.99 3.75V8.44C6.99 8.79 7.17 9.11 7.47 9.29Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                    Dokonaj płatności w ciągu:{" "}
                                    <span className="timer">{formattedTime}</span>
                                </div>
                                <button className="form-btn" id="step2-btn" onClick={()=>iPaid()}>
                                    OPŁATA WYKONANA
                                </button>
                            </div>
                        </div>
                        <div className="step-body step3-body disable">
                            <div className="spinner">
                                <img src="/img/waitorder.svg" alt="" />
                            </div>
                            <div className="step-text">
                                <span>Obecnie sprawdzamy twoją płatność </span>
                            </div>
                        </div>
                        <div className="step-body step4-body disable">
                            <div className="spinner">
                                <img src="/img/wrongorder.svg" alt="" />
                            </div>
                            <div className="step-text">
                                <span>
                                    Zlecenie nie zostało przetworzone,
                                    skontaktuj się z czatem online{" "}
                                </span>
                            </div>
                            <div className="step-note"></div>
                            <div className="steps-btns">
                                <Link className="form-btn" to="/">
                                    Nowa wymiana
                                </Link>{" "}
                                <a
                                    href={telegramSupportLink}
                                    className="form-btn form-btn-chat"
                                >
                                    <img src="/img/icon_chat.svg" alt="" />
                                    Napisz do online czatu
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OrderScreen;
