import React from "react";
import { telegramSupportLink } from "../App";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = () => {
	return (
<footer>
  <div className="container">
    <div className="row">
      <div className="col-sm-6 col-xs-12">
        <div className="footer-left">
          <svg xmlns="http://www.w3.org/2000/svg" baseProfile="basic" viewBox="0 0 1250 261" style={{height: '32px', margin: '5px 0px', display: 'block'}}>
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="255.003" y1={261} x2="255.003" y2={0}>
              <stop offset={0} stopColor="#1c1c1c" />
              <stop offset={1} stopColor="#4b4b4b" />
            </linearGradient>
            <path fill="url(#a)" d="M510 261H30.4C13.6 261 0 247.4 0 230.6V30.4C0 13.6 13.6 0 30.4 0H510v261z">
            </path>
            <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="880.003" y1={261} x2="880.003" y2={0}>
              <stop offset={0} stopColor="#bcbcbc" />
              <stop offset={1} stopColor="#f3f3f3" />
            </linearGradient>
            <path fill="url(#b)" d="M1219.6 261H510V0h709.6c16.8 0 30.4 13.6 30.4 30.4v200.3c0 16.7-13.6 30.3-30.4 30.3z">
            </path>
            <g fill="#FFF">
              <path d="M155.8 158.3c0 9.9-1.4 17.8-4.1 23.7-2.7 5.9-6.3 10.4-10.6 13.4-4.3 3.1-9.1 5.1-14.4 6.1-5.3 1-10.5 1.5-15.6 1.5H60.4V59.1h55.8c7.2 0 13 1.2 17.6 3.6 4.6 2.4 8.2 5.5 10.9 9.4 2.7 3.9 4.5 8.2 5.5 12.9 1 4.7 1.4 9.4 1.3 13.9-.2 13.1-3.5 22-9.9 26.7 4.2 2 7.6 5.6 10.3 10.8 2.6 5.3 3.9 12.6 3.9 21.9zm-31.2-58.9c0-4.5-.5-7.9-1.6-10.1-1.1-2.2-3.2-3.3-6.4-3.3H87.7v28.7H114c4.1 0 6.8-1.4 8.3-4.1 1.5-2.8 2.3-6.6 2.3-11.2zm3.8 59.1c0-4-.3-7.2-1-9.5-.6-2.3-1.5-4.2-2.5-5.5-1-1.3-2.1-2.2-3.4-2.6-1.2-.4-2.4-.6-3.6-.6H87.7v34.5h27.9c4.8 0 8.2-1.3 10-3.8 1.9-2.6 2.8-6.7 2.8-12.5zM265.2 203h-91.4V59.1h89.8V87H201v26.5h57.6v27.9H201v33.5h64.2V203zM371 160.5c0 30.3-16.3 45.5-48.9 45.5-15.8 0-27.9-4.5-36.2-13.4-8.3-9-12.4-22.8-12.4-41.5H300c.2 4.1.7 8 1.4 11.4.7 3.5 2 6.4 3.8 8.9 1.8 2.5 4.1 4.4 7 5.8s6.6 2.1 11.1 2.1c7.4 0 12.7-1.4 16-4.1 3.3-2.7 5-8.1 5-15.9 0-4.1-.7-7.1-2-8.9-1.3-1.8-3.4-3.2-6.2-4.3-3.2-1.2-7.1-2.1-11.6-2.8-4.5-.7-9.7-1.8-15.5-3.4-4.5-1.3-8.7-2.8-12.5-4.5-3.8-1.7-7.2-4-10.1-7.1-2.9-3.1-5.1-7.1-6.7-12-1.6-4.9-2.4-11.4-2.4-19.4 0-5.9.7-11 2.2-15.3 1.4-4.3 3.4-8 5.8-11s5.2-5.4 8.3-7.3c3.2-1.9 6.4-3.3 9.7-4.3 3.3-1 6.6-1.7 9.9-2 3.3-.3 6.4-.5 9.1-.5 9.4 0 17.1 1.5 22.9 4.4 5.9 2.9 10.4 6.8 13.7 11.4 3.3 4.7 5.5 10 6.6 15.8 1.1 5.9 1.7 11.7 1.7 17.4h-26c-.4-2.4-.7-5.1-1-8-.2-2.9-.9-5.5-1.9-7.6-.9-1.9-2.4-3.6-4.7-5.1-2.3-1.5-5.9-2.3-10.8-2.3-3.8 0-7 .3-9.5 1s-4.4 1.7-5.8 3c-1.4 1.3-2.4 3.1-2.9 5.2s-.8 4.7-.8 7.6c0 3.5.8 6 2.3 7.5 1.5 1.5 4.4 2.8 8.6 3.9 4.4 1.2 8.8 2.2 13.3 3 4.5.8 8.8 1.8 13 2.9 4.2 1.1 8.1 2.6 11.7 4.5 3.6 1.9 6.8 4.4 9.5 7.7s4.9 7.5 6.4 12.6c1.7 5.1 2.4 11.5 2.4 19.1zM470.3 86.9h-32.9V203h-27.3V86.9h-32.9V59.1h93v27.8z">
              </path>
            </g>
            <g fill="#363636">
              <path d="M646.7 150.1c-.6 7.5-1.9 14.6-3.9 21.4-2 6.8-4.9 12.7-8.7 17.7-3.8 5.1-8.8 9.1-14.9 12.1s-13.5 4.5-22.2 4.5c-10 0-18.5-1.7-25.5-5.1-7-3.4-12.6-8.4-17-14.8-4.3-6.5-7.5-14.5-9.4-24-2-9.5-3-20.3-3-32.5 0-11.2 1.1-21.4 3.2-30.4s5.4-16.7 9.9-23.1c4.5-6.3 10.2-11.2 17-14.6 6.9-3.4 15.1-5.1 24.6-5.1 9.6 0 17.4 1.5 23.5 4.5 6.1 3 10.9 6.9 14.6 11.7 3.6 4.8 6.2 10.3 7.8 16.4 1.6 6.1 2.7 12.3 3.4 18.4h-25.8c-.6-3.2-1.3-6.3-2.1-9.2-.7-2.9-1.9-5.5-3.4-7.6-1.5-2.1-3.6-3.8-6.2-5.1-2.6-1.3-6.1-1.9-10.5-1.9-5.3 0-9.8.9-13.4 2.6-3.6 1.7-6.6 4.5-8.8 8.4-2.2 3.9-3.8 9-4.8 15.3-1 6.3-1.4 14.1-1.4 23.4 0 9.4.7 16.9 2 22.8 1.3 5.8 3.2 10.4 5.6 13.6 2.4 3.3 5.3 5.5 8.8 6.7 3.5 1.2 7.3 1.8 11.6 1.8 4.4 0 7.8-.7 10.4-2 2.6-1.3 4.6-3.2 6.1-5.7s2.7-5.4 3.5-8.8c.9-3.4 1.7-7.2 2.6-11.5h26.4zM756.2 203H729v-62.1h-41V203h-27.2V59.1H688v53.7h41V59.1h27.2V203zM870.9 203h-28.5l-7.5-29.7h-36.2l-7.4 29.7h-27.5l39.4-143.9h27L870.9 203zm-42.8-56.5l-11.2-46.3-11.5 46.3h22.7zM950.8 203l-41.6-87.6V203H882V59.1h26.7l41.9 87v-87h27V203h-26.8zM1100.3 203h-22.1v-14.6c-1.6 3.5-3.6 6.3-6 8.5s-5 3.9-7.8 5.2c-2.8 1.3-5.8 2.1-8.8 2.6-3 .5-6 .7-8.9.7-10 0-18.4-1.9-25.1-5.8-6.7-3.9-12.1-9.2-16.2-16-4.1-6.8-7-14.9-8.7-24.2-1.7-9.3-2.6-19.3-2.6-30 0-48.8 18.3-73.2 55-73.2 9.1 0 16.7 1.5 23 4.4 6.3 2.9 11.4 6.9 15.3 11.7 3.9 4.9 6.7 10.5 8.4 16.7 1.7 6.3 2.6 12.8 2.6 19.4h-27.8c0-2.9-.2-5.9-.6-9-.4-3.1-1.3-5.8-2.7-8.2-1.4-2.4-3.6-4.4-6.5-5.9S1054 83 1049 83c-5 0-9.3.8-12.7 2.5-3.5 1.7-6.3 4.3-8.5 7.9-2.2 3.6-3.8 8.3-4.8 14-1 5.7-1.5 12.6-1.5 20.6 0 8.8.4 16.4 1.3 22.7.9 6.3 2.4 11.4 4.6 15.2 2.2 3.9 5.1 6.7 8.7 8.5 3.6 1.8 8.2 2.7 13.8 2.7 3.2 0 6.2-.5 9.1-1.5s5.4-2.6 7.5-4.7c2.1-2.1 3.8-4.9 5-8.2 1.2-3.3 1.7-7.3 1.6-12h-25.6v-26.5h53V203zM1208.6 203h-91.2V59.1h89.6V87h-62.4v26.5h57.4v27.9h-57.4v33.5h64V203z">
              </path>
            </g>
          </svg>
          <img src="img/logo.svg" alt="" />
          <div className="footer-text">
            <span>Sprzedawaj kryptowaluty 24h/365 dni w roku.</span>
            <br />
            <span>© netex24.club - Polski Portal P2P. Wszelkie Prawa Zastrzeżone. Copyright © 2019 -
              2023</span>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xs-12">
        <div className="footer-right">
          <ul className="footer-menu">
            <li>
              <Link to="/pages/terms">Regulamin</Link>
            </li>
            <li>
              <Link to="/site/contacts">Kontakt</Link>
            </li>
          </ul>
          <ul className="social">
            <li>
              <a href={telegramSupportLink} target="_blank"  rel="noopener noreferrer">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={42} height={42} rx={6} fill="white" />
                  <path d="M7.34738 21.3728C9.70035 20.1481 12.3269 19.1259 14.781 18.0985C19.003 16.4157 23.2418 14.762 27.5234 13.2225C28.3564 12.9602 29.8532 12.7037 30 13.8702C29.9196 15.5215 29.589 17.1631 29.3623 18.8047C28.7867 22.4147 28.1214 26.0123 27.4727 29.6105C27.2492 30.809 25.6604 31.4294 24.6437 30.6624C22.2005 29.103 19.7385 27.5587 17.3265 25.963C16.5364 25.2044 17.2691 24.1149 17.9747 23.5731C19.987 21.6992 22.1211 20.1071 24.0282 18.1363C24.5427 16.9624 23.0227 17.9517 22.5213 18.2548C19.7664 20.0487 17.079 21.9522 14.1745 23.5288C12.6909 24.3005 10.9617 23.641 9.47881 23.2104C8.1492 22.6902 6.20079 22.1661 7.34725 21.3729L7.34738 21.3728Z" fill="#48423B" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>



	);
};

export default Footer;
