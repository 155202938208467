import React from "react";
const NotFound = () => {
	window.location.href="/";
	return (
		<></>

	);
};

export default NotFound;
