import { makeAutoObservable } from "mobx";

class Store {
    variables = { currencyFromOpened:false,currencyToOpened:false,wrapperRefs:[],dropDownOpened:false };

    constructor() {
        makeAutoObservable(this);
    }
    setCurrencyFromOpened=(newState)=>{
        this.variables={...this.variables,currencyFromOpened:newState};
    }

    setCurrencyToOpened=(newState)=>{
        this.variables={...this.variables,currencyToOpened:newState};
    }
    setDropDownOpened=(newState)=>{
        this.variables={...this.variables,dropDownOpened:newState}
    }

   
}
export default new Store();
