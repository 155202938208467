import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Rules=()=>{
    return (<div className="page">
    <div className="page__top">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="page__h1">Regulamin serwisu</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-9">
          <div className="page__content">
            <p />
            <p>Regulamin serwisu Netex24</p>
            <p>1. . Pojęcia ogólne</p>
            <p>1.1.Regulamin – niniejszy regulamin.</p>
            <p>Usługodawca – firma „Polkoin” z adresem siedziby: ul. Zagłoby 5, 20-717 Lublin, NIP: 7123255793,.</p>
            <p>Usługobiorca – każda osoba fizyczna, uzyskująca dostęp do Serwisu i korzystająca z usług świadczonych za pośrednictwem Serwisu przez Usługodawcę.</p>
            <p>Komunikacja Drogą Elektroniczną – Komunikacja pomiędzy stronami za pośrednictwem poczty elektronicznej (e-mail) oraz formularzy kontaktowych dostępnych na stronie www.</p>
            <p>1.5. Przed rozpoczęciem korzystania z Serwisu, Usługobiorca zobowiązany jest zapoznać się z Regulaminem oraz Polityką prywatności.</p>
            II. Postanowienia ogólne
            <p>Regulamin, określa zasady funkcjonowania i użytkowania Serwisu oraz określa zakres praw i obowiązków Usługobiorców i Usługodawcy związanych z użytkowaniem Serwisu.</p>
            <p>Przedmiotem usług Usługodawcy jest udostępnienie nieodpłatnych narzędzi w postaci Serwisu, umożliwiających Usługobiorcom dostęp do treści w postaci wpisów, artykułów i materiałów audiowizualnych lub aplikacji internetowych i formularzy elektronicznych</p>   
            <p>Wszelkie ewentualne treści, artykuły i informacje zawierające cechy wskazówek lub porad publikowane na łamach Serwisu są jedynie ogólnym zbiorem informacji i nie są kierowane do poszczególnych Usługobiorców. Usługodawca nie ponosi odpowiedzialności za wykorzystanie ich przez Usługobiorców.</p>
            <p>Usługobiorca bierze na siebie pełną odpowiedzialność za sposób wykorzystania materiałów udostępnianych w ramach Serwisu w tym za wykorzystanie ich zgodnie z obowiązującymi przepisami prawa.</p>
            <p>Usługodawca nie udziela żadnej gwarancji co do przydatności materiałów umieszczonych w Serwisie.</p>    
            <p>Usługodawca nie ponosi odpowiedzialności z tytułu ewentualnych szkód poniesionych przez Usługobiorców Serwisu lub osoby trzecie w związku z korzystaniem z Serwisu. Wszelkie ryzyko związane z korzystaniem z Serwisu, a w szczególności z używaniem i wykorzystywaniem informacji umieszczonych w Serwisie, ponosi Usługobiorca korzystający z usług Serwisu.</p>
            <p> III. Warunki używania Serwisu</p>
            <p>Używanie Serwisu przez każdego z Usługobiorców jest nieodpłatne i dobrowolne.</p>
            <p>Usługobiorcy mają obowiązek zapoznania się z Regulaminem oraz pozostałymi dokumentami stanowiącymi jego integralną część i muszą zaakceptować w całości jego postanowienia w celu dalszego korzystania z Serwisu.</p>
            <p>Usługobiorcy nie mogą wykorzystywać żadnych pozyskanych w Serwisie danych osobowych do celów marketingowych</p>
            <p>W celu zapewnienia bezpieczeństwa Usługodawcy, Usługobiorcy oraz innych Usługobiorców korzystających z Serwisu, wszyscy Usługobiorcy korzystający z Serwisu powinni stosować się do ogólnie przyjętych zasad bezpieczeństwa w sieci</p>
            <p>4. Zabrania się działań wykonywanych osobiście przez Usługobiorców lub przy użyciu oprogramowania:</p>
            <p>- bez zgody pisemnej, dekompilacji i analizy kodu źródłowego,</p>
            <p>-bez zgody pisemnej, powodujących nadmierne obciążenie serwera Serwisu,</p>
            <p>-bez zgody pisemnej, prób wykrycia luk w zabezpieczeniach Serwisu i konfiguracji serwera,</p>
            <p>podejmowania prób wgrywania lub wstrzykiwania na serwer i do bazy danych kodu, skryptów i oprogramowania mogących wyrządzić szkodę oprogramowaniu Serwisu, innym Usługobiorcom lub Usługodawcy,</p>
            <p>podejmowania prób wgrywania lub wstrzykiwania na serwer i do bazy danych kodu, skryptów i oprogramowania mogących śledzić lub wykradać dane Usługobiorców lub Usługodawcy,</p>
            <p>podejmowania jakichkolwiek działań mających na celu uszkodzenie, zablokowanie działania Serwisu lub uniemożliwienie realizacji celu w jakim działa Serwis.</p>                    
            <p>W przypadku wykrycia zaistnienia lub potencjalnej możliwości zaistnienia incydentu Cyberbezpieczeństwa lub naruszenia RODO, Usługobiorcy w pierwszej kolejności powinni zgłosić ten fakt Usługodawcy w celu szybkiego usunięcia problemu / zagrożenia i zabezpieczenia interesów wszystkich Usługobiorców Serwisu.</p><p>
            </p><p> IV. Warunki komunikacji i świadczenia pozostałych usług w Serwisie</p>
            <p>-Serwis udostępnia usługi i narzędzia umożliwiające Usługobiorcom interakcję z Serwisem w postaci:</p>
            <p> -Formularz kontaktowy</p>
            <p> -Aplikacje internetowe i formularze elektroniczne</p>
            <p> -W przypadku kontaktu Usługobiorcy z Usługodawcą, dane osobowe Usługobiorców będą przetwarzane zgodnie z „Polityką Prywatności„, stanowiącą integralną część Regulaminu.</p>
            <p> V. Gromadzenie danych o Usługobiorcach</p>
            <p> W celu prawidłowego świadczenia usług przez Serwis, zabezpieczenia prawnego interesu Usługodawcy oraz w celu zapewnienia zgodności działania Serwisu z obowiązującym prawem, Usługodawca za pośrednictwem Serwisu gromadzi i przetwarza niektóre dane o Użytkownikach.</p>
            <p> W celu prawidłowego świadczenia usług, Serwis wykorzystuje i zapisuje niektóre anonimowe informacje o Usługobiorcy w plikach cookies.</p>
            <p> Zakres, cele, sposób oraz zasady przetwarzania danych dostępne są w załącznikach do Regulaminu: „Obowiązek informacyjny RODO” oraz w „Polityce prywatności„, stanowiących integralną część Regulaminu.</p>
            <p> Dane zbierane automatycznie:</p>
            <p> Do sprawnego działania Serwisu oraz do statystyk zbieramy automatycznie niektóre dane o Usługobiorcy. Do danych tych należą:</p>
            <p>-Adres IP</p>
            <p>-Typ przeglądarki</p>
            <p>-Rozdzielczość ekranu</p>
            <p>-Przybliżona lokalizacja</p>
            <p>-Otwierane podstrony serwisu</p>
            <p>-Czas spędzony na odpowiedniej podstronie serwisu</p>                    
            <p>-Rodzaj systemu operacyjnego</p> 
            <p> VI. Prawa autorskie</p> 
            <p>-Właścicielem Serwisu oraz praw autorskich do serwisu jest Usługodawca.</p> 
            <p>-Część danych zamieszczonych w Serwisie są chronione prawami autorskimi należącymi do firm, instytucji i osób trzecich, niepowiązanych w jakikolwiek sposób z Usługodawcą, i są wykorzystywane na podstawie uzyskanych licencji, lub opartych na licencji darmowej.</p> 
            <p>-Na podstawie Ustawy z dnia 4 lutego 1994 o prawie autorskim zabrania się wykorzystywania, kopiowania, reprodukowania w jakiejkolwiek formie oraz przetrzymywania w systemach wyszukiwania z wyłączeniem wyszukiwarki Google, Bing, Yahoo, NetSprint, DuckDuckGo, Facebook oraz LinkedIn jakichkolwiek artykułów, opisów, zdjęć oraz wszelkich innych treści, materiałów graficznych, wideo lub audio znajdujących się w Serwisie bez pisemnej zgody lub zgody przekazanej za pomocą Komunikacji Drogą Elektroniczną ich prawnego właściciela.</p> 
            <p>-Zgodnie z Ustawą z dnia 4 lutego 1994 o prawie autorskim ochronie nie podlegają proste informacje prasowe, rozumiane jako same informacje, bez komentarza i oceny ich autora. Autor rozumie to jako możliwość wykorzystywania informacji z zamieszczonych w serwisie tekstów, ale już nie kopiowania całości lub części artykułów o ile nie zostało to oznaczone w poszczególnym materiale udostępnionym w Serwisie.</p> 
            <p>VII. Zmiany Regulaminu</p> 
            <p>-Wszelkie postanowienia Regulaminu mogą być w każdej chwili jednostronnie zmieniane przez Usługodawcę, bez podawania przyczyn.</p> 
            <p>-Zmiany Regulaminu wchodzą w życie natychmiast po ich publikacji.</p> 
            <p>-Traktuje się iż każdy Usługobiorca, kontynuujący korzystanie z Serwisu po zmianie Regulaminu akceptuje go w całości.</p> 
            <p>VIII. Postanowienia końcowe</p> 
            <p>-Usługodawca dokona wszelkich starań by usługi Serwisu były oferowane w sposób ciągły. Nie ponosi on jednak żadnej odpowiedzialności za zakłócenia spowodowane siłą wyższą lub niedozwoloną ingerencją Usługobiorców, osób trzecich czy działalnością zewnętrznych automatycznych programów.</p> 
            <p>-Usługodawca zastrzega sobie prawo do zmiany jakichkolwiek informacji umieszczonych w Serwisie w wybranym przez Usługodawcę terminie, bez konieczności uprzedniego powiadomienia Usługobiorców korzystających z usług Serwisu.</p> 
            <p>-Usługodawca zastrzega sobie prawo do czasowego, całkowitego lub częściowego wyłączenia Serwisu w celu jego ulepszenia, dodawania usług lub przeprowadzania konserwacji, bez wcześniejszego uprzedzania o tym Usługobiorców</p> 
            <p>-Usługodawca zastrzega sobie prawo do wyłączenia Serwisu na stałe, bez wcześniejszego uprzedzania o tym Usługobiorców.</p> 
            <p>-Usługodawca zastrzega sobie prawo do dokonania cesji w części lub w całości wszelkich swoich praw i obowiązków związanych z Serwisem, bez zgody i możliwości wyrażania jakichkolwiek sprzeciwów przez Usługobiorców</p> 
            <p>-Obowiązujące oraz poprzednie Regulaminy Serwisu znajduję się na tej podstronie pod aktualnym Regulaminem</p> 
            <p>-We wszelkich sprawach związanych z działalnością Serwisu należy kontaktować się z Usługodawcę korzystając z jednej z poniższych form kontaktu</p> 
            <p>-Używając formularza kontaktowego dostępnego w Serwisie</p> 
            <p> Kontakt przy użyciu wskazanych środków komunikacji wyłącznie w sprawach związanych z prowadzonym Serwisem</p> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="banner">
            <div className="banner-title">
              Sprzedawaj kryptowaluty 24h/365 dni w roku. </div>
            <Link className="form-btn banner-link" to="/">Zacząć wymiane</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Rules;