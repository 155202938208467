import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
    listProductSend,
    listProductReceive,
   
} from "../../Redux/Actions/productActions";
import { toast } from "react-toastify";
import uniqid from "uniqid";
import { createOrder } from "../../Redux/Actions/orderActions";
import { ORDER_CREATE_RESET } from "../../Redux/Constants/orderConstants";
import Toast from "../LoadingError/toast";

import OutsideAlerter from "../../outSideAlerter";
import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ShopSection = observer(() => {
    const { variables,setCurrencyToOpened,setCurrencyFromOpened } = mobxStore;
    let history = useHistory();
    const {currencyFromOpened,currencyToOpened}=variables;
    const [sendAmount, setSendAmount] = useState(1);
    const [receiveAmount, setReceiveAmount] = useState(0);
   
    const [senderEmail, setSenderEmail] = useState("");
    const [senderName, setSenderName] = useState("");
    const [senderWallet, setSenderWallet] = useState("");
    const dispatch = useDispatch();
    const [errorSumFrom,setErrorSumFrom]=useState(false);
    const [sendCurr, setSendCurr] = useState("bitcoin");
    const [receiveCurr, setRecieveCurr] = useState("IPKO");
    const toastId = React.useRef(null);
   

    const Toastobjects = useMemo(() => ({
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    }), []);

    
    const productSendList = useSelector((state) => state.productSendList);
    const orderCreate = useSelector((state) => state.orderCreate);
    const productRecieveList = useSelector((state) => state.productRecieveList);
    const { loadingReceive, productsReceive } = productRecieveList;
    const { loadingSend, productsSend } = productSendList;

    const { order, successOrderCreate, errorOrderCreate } = orderCreate;

   
    


    const setSendAmountHandler = useCallback((value) => {
        setSendAmount(value);
        if (!loadingSend && !loadingReceive) {
            setReceiveAmount(
                parseFloat(value *
                    productsSend.find((item) => item.apiName === sendCurr).rate *
                    productsReceive.find((item) => item.name === receiveCurr).rate).toFixed(2)
            );
        }
    }, [setSendAmount, loadingSend, loadingReceive, sendCurr, receiveCurr, productsSend, productsReceive]);
    
    const setReceiveAmountHandler = (value) => {
       
            setReceiveAmount(value)
        

        if (!loadingSend && !loadingReceive) {
            setSendAmount(
                parseFloat(value /
                    (productsSend.find((item) => item.apiName === sendCurr)
                        .rate *
                        productsReceive.find((item) => item.name === receiveCurr)
                            .rate)).toFixed(8)
            );
        }
    };
    
    const setSendCurrHandler = (itemName) => {
        setSendCurr(itemName);
        setCurrencyFromOpened(false);
    };




    const setRecieveCurrHandler = (itemName) => {
        setRecieveCurr(itemName);
        setCurrencyToOpened(false);
    };

   
   
   
    useEffect(() => {
        dispatch(listProductSend());
        dispatch(listProductReceive());
      
    }, [dispatch]);
   

    useEffect(()=>{
        
        if(!loadingSend&&!loadingReceive){
            setSendAmountHandler("1");
        }
    },[loadingSend,loadingReceive,setSendAmountHandler]);
    console.log(orderCreate);
    useEffect(() => {
        
        if (successOrderCreate) {
            history.push(`/order/${order._id}`);
            dispatch({ type: ORDER_CREATE_RESET });
        }
       else if (errorOrderCreate) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(errorOrderCreate, Toastobjects);
            }
          
        }
    }, [dispatch, history, successOrderCreate, order, errorOrderCreate]);



    const submitHandler = (e) => {
        e.preventDefault();
        if (!loadingReceive && !loadingSend) {
            const exchangeRate = parseFloat(
                productsSend.find((item) => item.apiName === sendCurr).rate *
                    productsReceive.find((item) => item.name === receiveCurr)
                        .rate
            );
            dispatch(
                createOrder({
                    sendAmount,
                    receiveAmount,
                    sendCurr,
                    receiveCurr,
                    exchangeRate,
                    senderEmail,
                    senderName,
                    senderWallet,
                })
            );
        }
    };
  
    return (
        <React.Fragment>
            <Toast/>
            <div
                className="backdrop"
                style={{
                    display: `${
                        currencyFromOpened || currencyToOpened
                            ? "block"
                            : "none"
                    }`,
                }}
            ></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="steps__form">
                            <form
                                id="form-step1"
                                className="form"
                                onSubmit={submitHandler}
                            >
                                <input type="hidden" />
                                <div className="course">
                                    Kurs wymiany
                                    <span>
                                        {!loadingSend && !loadingReceive
                                            ? `1 ${
                                                  productsSend.find(
                                                      (item) =>
                                                          item.apiName ===
                                                          sendCurr
                                                  ).name
                                              } - ${
                                                  (parseFloat(
                                                      productsSend.find(
                                                          (item) =>
                                                              item.apiName ===
                                                              sendCurr
                                                      ).rate
                                                  ) *
                                                  parseFloat(
                                                      productsReceive.find(
                                                          (item) =>
                                                              item.name ===
                                                              receiveCurr
                                                      ).rate
                                                  )).toFixed(2)
                                              } ${
                                                  productsReceive.find(
                                                      (item) =>
                                                          item.name ===
                                                          receiveCurr
                                                  ).sign
                                              }`
                                            : null}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="currency-label">
                                            Chcę oddać
                                        </label>
                                        <OutsideAlerter>
                                            <div
                                                className={`currency ${
                                                    currencyFromOpened
                                                        ? "opened"
                                                        : ""
                                                }`}
                                                id="currency-from"
                                               
                                            >
                                                <div className="currency-wrapper" >
                                                    <div className="currency-select">
                                                        <button
                                                            type="button"
                                                            className="currency-select__btn"
                                                            onMouseUp={() =>
                                                                setCurrencyFromOpened(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={`/uploads/${
                                                                    !loadingSend
                                                                        ? productsSend.find(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item.apiName ===
                                                                                  sendCurr
                                                                          ).icon
                                                                        : null
                                                                }`}
                                                                alt={`Netex24 ${`/uploads/${
                                                                    !loadingSend
                                                                        ? productsSend.find(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item.apiName ===
                                                                                  sendCurr
                                                                          ).name
                                                                        : null
                                                                }`} coin logo`}
                                                                id="btn-icon-from"
                                                            />
                                                            <span id="btn-name-from">
                                                                {!loadingSend
                                                                    ? productsSend.find(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.apiName ===
                                                                              sendCurr
                                                                      ).name
                                                                    : null}
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div className="currency-input currency-input-from" >
                                                        <input
                                                            type="number"
                                                            id="from_summ"
                                                            min={!loadingSend
                                                                ? productsSend.find(
                                                                      (
                                                                          item
                                                                      ) =>
                                                                          item.apiName ===
                                                                          sendCurr
                                                                  ).min
                                                                : "0"}
                                                            max={!loadingSend
                                                                ? productsSend.find(
                                                                      (
                                                                          item
                                                                      ) =>
                                                                          item.apiName ===
                                                                          sendCurr
                                                                  ).reserve:"10000000000"}
                                                            step={0.0000000001}
                                                            value={sendAmount}
                                                            onChange={(e) =>
                                                                setSendAmountHandler(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        />
                                                        <div className="currency-sign__from">
                                                            {!loadingSend
                                                                ? productsSend.find(
                                                                      (item) =>
                                                                          item.apiName ===
                                                                          sendCurr
                                                                  ).sign
                                                                : null}
                                                        </div>
                                                        <div className="currency-error error1" />
                                                    </div>
                                                </div>

                                                <div
                                                    className="currency-list-wrapper"
                                                    // style={{
                                                    //     display: `${
                                                    //         currencyFromOpened
                                                    //             ? "block"
                                                    //             : "none"
                                                    //     }`,
                                                    // }}
                                                >
                                                    <div className="currency-head">
                                                        Kryptowaluty{" "}
                                                    </div>
                                                    <ul className="currency-list">
                                                        {!loadingSend
                                                            ? productsSend.map(
                                                                  (item) => (
                                                                      <li
                                                                          className="currency-list__item"
                                                                          key={uniqid()}
                                                                          onClick={() =>
                                                                              setSendCurrHandler(
                                                                                  item.apiName
                                                                              )
                                                                          }
                                                                      >
                                                                          <img
                                                                              src={`/uploads/${item.icon}`}
                                                                              alt={`coin cryptocoin logo netex24 ${item.apiName}`}
                                                                          />
                                                                          <span>
                                                                              {
                                                                                  item.name
                                                                              }
                                                                          </span>
                                                                      </li>
                                                                  )
                                                              )
                                                            : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </OutsideAlerter>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="normal-input">
                                                    <svg
                                                        className="input-icon"
                                                        focusable="false"
                                                        viewBox="0 0 24 24"
                                                        aria-hidden="true"
                                                        role="presentation"
                                                    >
                                                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                                    </svg>
                                                    <input
                                                        type="email"
                                                        value={senderEmail}
                                                        onChange={(e) =>
                                                            setSenderEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="E-mail"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="currency-label currency-label-to">
                                            Chcę dostać
                                        </label>
                                        <OutsideAlerter>
                                            <div
                                                className={`currency ${
                                                    currencyToOpened
                                                        ? "opened"
                                                        : ""
                                                }`}
                                                id="currency-to"
                                            >
                                                <div className="currency-wrapper">
                                                    <div
                                                        className="currency-select"
                                                        onMouseUp={() =>
                                                            setCurrencyToOpened(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <button
                                                            type="button"
                                                            className="currency-select__btn"
                                                        >
                                                            <img
                                                                src={`/uploads/${
                                                                    !loadingReceive
                                                                        ? productsReceive.find(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item.name ===
                                                                                  receiveCurr
                                                                          ).icon
                                                                        : null
                                                                }`}
                                                                alt={`exchange crypto. crypto exchange. low price. the best. icon logo. ${
                                                                    !loadingReceive
                                                                        ? productsReceive.find(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item.name ===
                                                                                  receiveCurr
                                                                          ).icon
                                                                        : null
                                                                }`}
                                                                id="btn-icon-to"
                                                            />
                                                            <span id="btn-name-to">
                                                                {!loadingReceive
                                                                    ? productsReceive.find(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.name ===
                                                                              receiveCurr
                                                                      ).name
                                                                    : null}
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div className="currency-input currency-input-to">
                                                        <input
                                                            type="number"
                                                            id="to_summ"
                                                            value={
                                                                receiveAmount
                                                            }
                                                            onChange={(e) =>
                                                                setReceiveAmountHandler(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        />
                                                        <div className="currency-sign__to">
                                                            <span className="currency-sign__name">
                                                            {!loadingReceive
                                                                    ? productsReceive.find(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.name ===
                                                                              receiveCurr
                                                                      ).sign
                                                                    : null}
                                                            </span>
                                                            <span className="currency-sign__course">
                                                            {!loadingReceive
                                                                    ? productsReceive.find(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.name ===
                                                                              receiveCurr
                                                                      ).reserve
                                                                    : null}
                                                            </span>
                                                        </div>
                                                        <div className="currency-error error2" />
                                                    </div>
                                                </div>
                                                <div className="currency-list-wrapper">
                                                    <div className="currency-head">
                                                        Wybierz swój bank{" "}
                                                    </div>
                                                    <ul className="currency-list">
                                                        {!loadingReceive
                                                            ? productsReceive.map(
                                                                  (item) => (
                                                                      <li
                                                                          className="currency-list__item"
                                                                          onClick={() =>
                                                                              setRecieveCurrHandler(
                                                                                  item.name
                                                                              )
                                                                          }
                                                                          key={uniqid()}
                                                                      >
                                                                          <img
                                                                              src={`/uploads/${item.icon}`}
                                                                              alt={`crypto icon. best price. exchangeprice. ${item.name}`}
                                                                          />
                                                                          <span>
                                                                              {
                                                                                  item.name
                                                                              }
                                                                          </span>
                                                                      </li>
                                                                  )
                                                              )
                                                            : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </OutsideAlerter>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="normal-input normal-input-fio">
                                                    <svg
                                                        className="input-icon"
                                                        focusable="false"
                                                        viewBox="0 0 24 24"
                                                        aria-hidden="true"
                                                        role="presentation"
                                                    >
                                                        <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"></path>
                                                    </svg>
                                                    <input
                                                        type="text"
                                                        value={senderName}
                                                        onChange={(e) =>
                                                            setSenderName(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Imię Nazwisko"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-6">
                                                <div className="normal-input normal-input-account">
                                                    <img
                                                        src={`/uploads/${
                                                            !loadingReceive
                                                                ? productsReceive.find(
                                                                      (
                                                                          item
                                                                      ) =>
                                                                          item.name ===
                                                                          receiveCurr
                                                                  ).icon
                                                                : null
                                                        }`}
                                                        alt={`the best exchanger. crypto icon. ${!loadingReceive
                                                            ? productsReceive.find(
                                                                  (
                                                                      item
                                                                  ) =>
                                                                      item.name ===
                                                                      receiveCurr
                                                              ).icon
                                                            : ""} netex24`}
                                                        className="input-icon"
                                                        id="account-icon"
                                                    />
                                                    <input
                                                        type="text"
                                                        id="account"
                                                        value={senderWallet}
                                                        onChange={(e) =>
                                                            setSenderWallet(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Numer Konta"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-bottom">
                                            <div className="agree">
                                                <div className="wrap-check">
                                                    <input
                                                        type="checkbox"
                                                        id="check-agree"
                                                        required
                                                    />
                                                    <label
                                                        htmlFor="check-agree"
                                                        className="label-check"
                                                    >
                                                        <svg
                                                            className="svg-no"
                                                            width={16}
                                                            height={16}
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            style={{
                                                                borderRadius:
                                                                    "2px",
                                                            }}
                                                        >
                                                            <path
                                                                d="M0.5 0.5H15.5V15.5H0.5V0.5Z"
                                                                stroke="black"
                                                                strokeWidth={2}
                                                            />
                                                        </svg>
                                                        <svg
                                                            className="svg-yes"
                                                            width={16}
                                                            height={16}
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0.5 0.5H15.5V15.5H0.5V0.5Z"
                                                                stroke="white"
                                                            />
                                                            <path
                                                                d="M4 6.5L7.5 10L12.5 5"
                                                                stroke="black"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </label>
                                                </div>
                                                <label
                                                    htmlFor="check-agree"
                                                    className="agree_text"
                                                >
                                                    Wyrażam zgodę na
                                                    przetwarzanie moich danych
                                                    osobowych w celach
                                                    kontaktowych{" "}
                                                    <Link to="/pages/rules">
                                                        regulamin
                                                    </Link>
                                                </label>
                                            </div>
                                            <input
                                                type="hidden"
                                                id="maildata"
                                                defaultValue="false"
                                            />
                                            <button
                                                className="form-btn"
                                                id="step1-btn"
                                                type="submit"
                                            >
                                                Złóż zamówienie
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default ShopSection;
