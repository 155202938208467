import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { telegramSupportLink } from "../App";

const Contacts = () => {
    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page__h1">Kontakt do serwisu</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <div className="contacts">
                            <ul className="contacts-list">
                                <li className="contact-item">
                                    <a
                                        className="contact-link"
                                        href={telegramSupportLink}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img src="../img/telegram.svg" alt="" />
                                        Telegram
                                    </a>
                                </li>
                            </ul>
                            <div className="contact-note">
                                Jeżeli masz jakiekolwiek pytania, zapraszamy do
                                kontaktu w Telegram{" "}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="banner">
                            <div className="banner-title">
                                Sprzedawaj kryptowaluty 24h/365 dni w roku.{" "}
                            </div>
                            <Link className="form-btn banner-link" to="/">
                                Zacząć wymiane
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Contacts;
