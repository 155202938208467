import React from "react";

import mobxStore from "../mobx/mobxStore";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OutsideAlerter from "../outSideAlerter";
import { observer } from "mobx-react-lite";
import { telegramSupportLink } from "../App";
const Header = observer(()=>{
    const {setDropDownOpened,variables}=mobxStore
    
  

    return (
        <OutsideAlerter>
            <header id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header__wrapper">
                                <button className="menuBtn">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={25}
                                        viewBox="0 0 30 25"
                                        fill="none"
                                    >
                                        <rect
                                            id="line01"
                                            width={30}
                                            height={3}
                                            rx="1.5"
                                            fill="black"
                                        />
                                        <rect
                                            id="line02"
                                            y={11}
                                            width={30}
                                            height={3}
                                            rx="1.5"
                                            fill="black"
                                        />
                                        <rect
                                            id="line03"
                                            y={22}
                                            width={30}
                                            height={3}
                                            rx="1.5"
                                            fill="black"
                                        />
                                    </svg>
                                </button>
                                <div className="menuBtn-shadow" />
                                <Link className="logo" to="/">
                                    <img src="/img/logo.svg" alt="logo" />
                                </Link>
                                <div className="menu">
                                    <ul className="menu-list">
                                        <li className="menu__item">
                                            <Link
                                                className="menu__link"
                                                to="/site/questions"
                                            >
                                                Często zadawane pytania
                                            </Link>
                                        </li>
                                        <li className="menu__item">
                                            <Link
                                                className="menu__link"
                                                to="/pages/rules"
                                            >
                                                Regulamin
                                            </Link>
                                        </li>
                                        <li className="menu__item">
                                            <Link
                                                className="menu__link"
                                                to="/site/contacts"
                                            >
                                                Kontakt
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="mobile-lang">
                                        <div className="dropdown dropdown-languages">
                                            <a
                                                href="/"
                                                data-toggle="dropdown"
                                                className="dropdown-languages__toggle"
                                            >
                                                pl
                                                <svg
                                                    width={12}
                                                    height={8}
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{ width: "18px" }}
                                                >
                                                    <path
                                                        d="M5.26545 7.20482C5.66137 7.63342 6.33863 7.63342 6.73455 7.20482L11.3776 2.17855C11.9693 1.53807 11.515 0.5 10.6431 0.5H1.35693C0.484997 0.5 0.0307255 1.53807 0.622376 2.17855L5.26545 7.20482Z"
                                                        fill="#48423B"
                                                    />
                                                </svg>
                                            </a>
                                            <ul
                                                id="w0"
                                                className="dropdown-menu"
                                            >
                                                <li>
                                                    <a
                                                        href="/"
                                                        tabIndex={-1}
                                                    >
                                                        pl
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/en"
                                                        tabIndex={-1}
                                                    >
                                                        en
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    href={telegramSupportLink}
                                    className="telegram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>Telegram</span>
                                </a>
                                <div className="lang" onClick={()=>setDropDownOpened(true)}>
                                    <div className={`dropdown dropdown-languages ${variables.dropDownOpened?"open":""}`}>
                                        <span
                                            
                                            
                                            className="dropdown-languages__toggle"
                                        >
                                            PL
                                            <svg
                                                width={12}
                                                height={8}
                                                viewBox="0 0 12 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{ width: "18px" }}
                                            >
                                                <path
                                                    d="M5.26545 7.20482C5.66137 7.63342 6.33863 7.63342 6.73455 7.20482L11.3776 2.17855C11.9693 1.53807 11.515 0.5 10.6431 0.5H1.35693C0.484997 0.5 0.0307255 1.53807 0.622376 2.17855L5.26545 7.20482Z"
                                                    fill="#48423B"
                                                />
                                            </svg>
                                        </span>
                                        <ul id="w1" className="dropdown-menu">
                                            <li>
                                                <a href="/" tabIndex={-1}>
                                                    PL
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/en" tabIndex={-1}>
                                                    en
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </OutsideAlerter>
    );
}) 
;

export default Header;
