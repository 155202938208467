import React from "react";
import ShopSection from "../components/homeComponents/shopSection";
import Step1BG from "./step1Bg";

const HomeScreen = () => {
   

    return (
        <React.Fragment>
           <ShopSection/>
            <Step1BG/>
        </React.Fragment>
    );
};

export default HomeScreen;
