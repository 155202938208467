import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Questions = () => {
    
    useEffect(()=>{
        const elems=document.querySelectorAll(".qitem");
        for(let i=0;i<elems.length;i++){
            elems[i].addEventListener("click",function(){
                elems[i].classList.toggle("open")
            })
        }
    },[])


    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page__h1">Użytkownikam</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <div className="qtabs-wrap">
                            <ul className="qtabs">
                                <li className="qtabs-label" data-type={1}>
                                    Pytania ogólne
                                </li>
                                <li className="qtabs-label" data-type={2}>
                                    Jak to działa?
                                </li>
                            </ul>
                        </div>
                        <ul className="qlist">
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Opłaty i prowizje
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Za sprzedaż kryptowalut pobieramy prowizję
                                    zależną od kwoty transakcji: - do kwoty 4
                                    0000 zł pobieramy 1,75% od sprzedaży - od 4
                                    000 zł do 20 0000 zł 1,5% od sprzedaży, -
                                    powyżej 20 0000 zł pobieramy i 1,25% od
                                    sprzedaży. Przy sprzedaży natomiast możesz
                                    wypłacić pieniądze na konto bankowe
                                    przelewem tradycyjnym za darmo.
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Weryfikacja tożsamości{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Sprzedaż do 4000 zł nie wymaga weryfikacji
                                    tożsamości, wystarczy podać numer telefonu,
                                    adres e-mail i wybrać bank na który chcesz
                                    dostać środki{" "}
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Jak długo trwa realizacja transakcji?{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Czas realizacji wypłaty złotówek na konto
                                    bankowe zależy rodzaju przelewu który
                                    wybiera uzytkownik. Dostępne przelewy:
                                    Przelew zwykly - czas realizacji to 24
                                    godziny; Przelew natychmiastowy - czas
                                    realizacji to 15 minut; Przelew SORBNET
                                    -czas realizacji to 1 godzina
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Bezpieczeństwo{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Serwis internetowy kantoru kryptowalut
                                    netex24.club zabezpieczamy takim samym
                                    protokołem bezpieczeństwa SSL 256, jaki
                                    stosują banki. Symbolizuje go kłódka
                                    widoczna w adresie strony internetowej
                                    Netex24 i Twojego banku.
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Co to jest kurs, skąd go bierzecie i
                                        gdzie go sprawdzę?{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Kurs bitcoina, ethereum i innych kryptowalut
                                    wyceniany jest przez rynek, podobnie jak w
                                    przypadku każdej innej waluty czy aktywa.
                                    Cena określana jest za pomocą siły popytu
                                    oraz podaży. Kurs pobieramy z giełdy, na
                                    której kupujemy środki dla naszych
                                    użytkowników dodając do niego naszą
                                    prowizję.{" "}
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Jak szybko trwa wymiana?{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Po otrzymaniu kryptowaluty czas wymiany trwa
                                    od 10 do 20 minut{" "}
                                </div>
                            </li>
                            <li className="qitem qitem1">
                                <div className="qitem-wrap">
                                    <div className="q-question">
                                        Nie mogę dokonać wymiany{" "}
                                    </div>
                                    <svg
                                        width={46}
                                        height={10}
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25235 9.15827C7.65017 9.60615 8.34983 9.60615 8.74765 9.15827L15.1822 1.91409C15.7551 1.26911 15.2972 0.25 14.4345 0.25H1.5655C0.702821 0.25 0.244948 1.26911 0.817841 1.91409L7.25235 9.15827Z"
                                            fill="#48423B"
                                        />
                                    </svg>
                                </div>
                                <div className="q-answer">
                                    Jeśli nie możesz dokonać wymiany. skontaktuj
                                    się z naszym działem obsługi klienta{" "}
                                </div>
                            </li>
                            
                            <div className="col-md-3">
                                <div className="banner">
                                    <div className="banner-title">
                                        Sprzedawaj kryptowaluty 24h/365 dni w
                                        roku{" "}
                                    </div>
                                    <Link
                                        className="form-btn banner-link"
                                        to="/"
                                    >
                                        Zacząć wymiane
                                    </Link>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questions;
