import React from "react";
import { observer } from "mobx-react-lite";
import "./App.css";

import Footer from "./components/footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/homeScreen";
import OrderScreen from "./screens/orderScreen";
import NotFound from "./screens/notFound";
import Header from "./components/header";



import "react-toastify/dist/ReactToastify.css";


import Questions from "./screens/questions";
import Rules from "./screens/rules";
import Contacts from "./screens/contacts";
import Terms from "./screens/terms";

const App = observer(() => {
    return (
        <React.Fragment>
            <Router>
                <Header />
                <div className="content">
                    <Switch>
                        <Route path="/" component={HomeScreen} exact />
                        <Route path="/order/:id" component={OrderScreen} />
                        <Route path="/site/questions" component={Questions} />
                        <Route path="/pages/rules" component={Rules} />
                        <Route path="/site/contacts" component={Contacts} />
                        <Route path="/pages/terms" component={Terms}/>
                        <Route path="*" component={NotFound} />
                    </Switch>
                </div>

                <Footer />
            </Router>
        </React.Fragment>
    );
});

export default App;

export const serverLink = "https://api.netex24.club";
//export const serverLink = "http://localhost:6007";
//export const clientSideLink = "https://dotsatoshi.top";
export const telegramSupportLink = "https://t.me/Alex_Netex_Support";