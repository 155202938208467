import React from "react";

const Step1BG=()=>{
        return (<div className="step1bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <ul className="reserve-list">
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/qiwi_1606567752.svg"
                                    alt="Qiwi"
                                />
                                <div className="reserve-name">IPKO</div>
                                <div className="reserve-summ">
                                    1268741.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/webmoney_1606572635.svg"
                                    alt="Webmoney"
                                />
                                <div className="reserve-name">
                                    Millenium
                                </div>
                                <div className="reserve-summ">
                                    155000.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/yandex_1606572766.svg"
                                    alt="Яндекс.Деньги"
                                />
                                <div className="reserve-name">
                                    mBank
                                </div>
                                <div className="reserve-summ">
                                    154001.42 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/bitcoinbtc_1607281332.svg"
                                    alt="Bitcoin"
                                />
                                <div className="reserve-name">
                                    Bitcoin
                                </div>
                                <div className="reserve-summ">
                                    10.00 BTC
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/bitcoincashbch_1607281508.svg"
                                    alt="Bitcoin Cash"
                                />
                                <div className="reserve-name">
                                    Bitcoin Cash
                                </div>
                                <div className="reserve-summ">
                                    45.00 BCH
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/drawing_1607282516.svg"
                                    alt="Bitcoin Gold"
                                />
                                <div className="reserve-name">
                                    Bitcoin Gold
                                </div>
                                <div className="reserve-summ">
                                    156.00 BTG
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/alfa_1607285990.svg"
                                    alt="Альфа-Клик ₽"
                                />
                                <div className="reserve-name">
                                    Alior Bank
                                </div>
                                <div className="reserve-summ">
                                    456321.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/tinkoff_1607286184.svg"
                                    alt="Тинькофф"
                                />
                                <div className="reserve-name">
                                    Pekao SA
                                </div>
                                <div className="reserve-summ">
                                    4561321.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/russianstandard_1607286410.svg"
                                    alt="Русский Стандарт "
                                />
                                <div className="reserve-name">
                                    CitiBank{" "}
                                </div>
                                <div className="reserve-summ">
                                    1235457.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/ethereumeth_1607284009.svg"
                                    alt="Ethereum"
                                />
                                <div className="reserve-name">
                                    Ethereum
                                </div>
                                <div className="reserve-summ">
                                    451.00 ETH
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/stellarxlm_1607284178.svg"
                                    alt="Stellar"
                                />
                                <div className="reserve-name">
                                    Stellar
                                </div>
                                <div className="reserve-summ">
                                    24524.00 XLM
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/etherclassicetc_1607284331.svg"
                                    alt="Ether Classic"
                                />
                                <div className="reserve-name">
                                    Ether Classic
                                </div>
                                <div className="reserve-summ">
                                    42541.00 ETC
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/ukrsibbank_1621771560.svg"
                                    alt="УкрСиббанк"
                                />
                                <div className="reserve-name">
                                    BNP Paribas
                                </div>
                                <div className="reserve-summ">
                                    155000.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/vtb24_1607286347.svg"
                                    alt="ВТБ24"
                                />
                                <div className="reserve-name">
                                    Santander Bank
                                </div>
                                <div className="reserve-summ">
                                    1321255.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/promsvyazbank_1607286488.png"
                                    alt="Промсвязьбанк"
                                />
                                <div className="reserve-name">
                                    Ing Bank Śląski
                                </div>
                                <div className="reserve-summ">
                                    1234647.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/litecoinltc_1607284440.svg"
                                    alt="Litecoin"
                                />
                                <div className="reserve-name">
                                    Litecoin
                                </div>
                                <div className="reserve-summ">
                                    74724.00 LTC
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/ripplexrp_1607284685.svg"
                                    alt="Ripple"
                                />
                                <div className="reserve-name">
                                    Ripple
                                </div>
                                <div className="reserve-summ">
                                    424522.00 XRP
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/moneroxmr_1607284815.svg"
                                    alt="Monero"
                                />
                                <div className="reserve-name">
                                    Monero
                                </div>
                                <div className="reserve-summ">
                                    102554.00 XMR
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/mtsbank_1621767299.svg"
                                    alt="МТС Банк"
                                />
                                <div className="reserve-name">
                                    Bank Pocztowy
                                </div>
                                <div className="reserve-summ">
                                    1234647.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/pochtabank_1621767637.svg"
                                    alt="Почта Банк"
                                />
                                <div className="reserve-name">
                                    Get In Bank
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/alfabank_1621768931.svg"
                                    alt="Альфабанк ₴"
                                />
                                <div className="reserve-name">
                                    Idea Bank
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/dogecoindoge_1607284896.svg"
                                    alt="Dogecoin"
                                />
                                <div className="reserve-name">
                                    Dogecoin
                                </div>
                                <div className="reserve-summ">
                                    1400224.00 DOGE
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/dashdash_1607284971.svg"
                                    alt="Dash"
                                />
                                <div className="reserve-name">Dash</div>
                                <div className="reserve-summ">
                                    742444.00 DASH
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/zcashzec_1607285041.svg"
                                    alt="Zcash"
                                />
                                <div className="reserve-name">
                                    Zcash
                                </div>
                                <div className="reserve-summ">
                                    455701.00 ZEC
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/sovcombank_1621769579.svg"
                                    alt="Совкомбанк"
                                />
                                <div className="reserve-name">
                                    Plus Bank
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/kykyryza_1621769170.svg"
                                    alt="Кукуруза"
                                />
                                <div className="reserve-name">
                                    Nest Bank
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/mir_1621770624.svg"
                                    alt="Мир"
                                />
                                <div className="reserve-name">
                                    Revolut
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/1958_1621849326.png"
                                    alt="Tron"
                                />
                                <div className="reserve-name">Tron</div>
                                <div className="reserve-summ">
                                    235042.00 TRX
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/c-card-green_1607285629.svg"
                                    alt="Visa, MC ₴"
                                />
                                <div className="reserve-name">
                                    Visa, PLN
                                </div>
                                <div className="reserve-summ">
                                    4562201.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/tether-usdt-logo_1607514459.png"
                                    alt="Tether (USDT-TRC20)"
                                />
                                <div className="reserve-name">
                                    Tether (USDT-TRC20)
                                </div>
                                <div className="reserve-summ">
                                    483700.00 USDT
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/perfectmoney_1621771371.svg"
                                    alt="Perfect Money €"
                                />
                                <div className="reserve-name">
                                    Kanga Kantor
                                </div>
                                <div className="reserve-summ">
                                    120042.00 EUR
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/nem_1621769323.svg"
                                    alt="NEM"
                                />
                                <div className="reserve-name">
                                    Cinkciarz
                                </div>
                                <div className="reserve-summ">
                                    120042.00 XEM
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/tether-usdt-logo_1607514459.png"
                                    alt="РНКБ"
                                />
                                <div className="reserve-name">
                                    Tether (USDT-TRC20)
                                </div>
                                <div className="reserve-summ">
                                    483700.00 USDT
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/card_1607286252.svg"
                                    alt="Visa, MC ₽"
                                />
                                <div className="reserve-name">
                                    Visa,PLN
                                </div>
                                <div className="reserve-summ">
                                    12315441.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/bittorrent_1621766944.svg"
                                    alt="BitTorrent"
                                />
                                <div className="reserve-name">
                                    BitTorrent
                                </div>
                                <div className="reserve-summ">
                                    120042.00 BTT
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/huobitoken_1621767075.svg"
                                    alt="Huobi Token"
                                />
                                <div className="reserve-name">
                                    Huobi Token
                                </div>
                                <div className="reserve-summ">
                                    120042.00 HT
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/payeer_1621767489.svg"
                                    alt="Payeer $"
                                />
                                <div className="reserve-name">
                                    Payeer $
                                </div>
                                <div className="reserve-summ">
                                    155000.00 USD
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/tezos_1621767766.svg"
                                    alt="Tezos"
                                />
                                <div className="reserve-name">
                                    Tezos
                                </div>
                                <div className="reserve-summ">
                                    155000.00 XTZ
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/advcash_1621767914.svg"
                                    alt="AdvCash $"
                                />
                                <div className="reserve-name">
                                    AdvCash $
                                </div>
                                <div className="reserve-summ">
                                    120042.00 USD
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/payeer_1621769408.svg"
                                    alt="Payeer €"
                                />
                                <div className="reserve-name">
                                    Payeer €
                                </div>
                                <div className="reserve-summ">
                                    120042.00 EUR
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/advcash_1621769683.svg"
                                    alt="AdvCash €"
                                />
                                <div className="reserve-name">
                                    AdvCash €
                                </div>
                                <div className="reserve-summ">
                                    120042.00 EUR
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/neo_1621769987.svg"
                                    alt="NEO"
                                />
                                <div className="reserve-name">NEO</div>
                                <div className="reserve-summ">
                                    120042.00 NEO
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/payeer_1621770056.svg"
                                    alt="Payeer ₽"
                                />
                                <div className="reserve-name">
                                    Payeer PLN
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/visamastercard_1621770275.svg"
                                    alt="Visa, MC ₸"
                                />
                                <div className="reserve-name">
                                    Visa, PLN
                                </div>
                                <div className="reserve-summ">
                                    3228017.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/advcash_1621770331.svg"
                                    alt="AdvCash ₽"
                                />
                                <div className="reserve-name">
                                    AdvCash PLN
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/binance_1621770428.svg"
                                    alt="Binance Coin"
                                />
                                <div className="reserve-name">
                                    Binance Coin
                                </div>
                                <div className="reserve-summ">
                                    120042.00 BNB
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/trueusd_1621770978.svg"
                                    alt="TrueUSD"
                                />
                                <div className="reserve-name">
                                    TrueUSD
                                </div>
                                <div className="reserve-summ">
                                    120042.00 TUSD
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/advcash_1621771053.svg"
                                    alt="AdvCash ₴"
                                />
                                <div className="reserve-name">
                                    AdvCash PLN
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PLN
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/cardano_1621771160.svg"
                                    alt="Cardano"
                                />
                                <div className="reserve-name">
                                    Cardano
                                </div>
                                <div className="reserve-summ">
                                    120042.00 ADA
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/paxos_1621772047.svg"
                                    alt="Paxos"
                                />
                                <div className="reserve-name">
                                    Paxos
                                </div>
                                <div className="reserve-summ">
                                    120042.00 PAX
                                </div>
                            </div>
                        </li>
                        <li className="reserve-item">
                            <div className="reserve-wrap">
                                <img
                                    className="reserve-icon"
                                    src="/uploads/usdcoin_1621772308.svg"
                                    alt="USD Coin - ERC20"
                                />
                                <div className="reserve-name">
                                    USD Coin - ERC20
                                </div>
                                <div className="reserve-summ">
                                    120042.00 USDC
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <ul className="advantages">
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/1.png" alt="" />
                                <div className="advantages-name">
                                    Oplacalna wymiana 24/7
                                </div>
                                <div className="advantages-text">
                                Dzięki największemu wolumenowi
                                    obrotu na P2P oferujemy najlepsze
                                    kursy.Sprzedawaj bitcoin 24h/365 dni
                                    w roku.
                                </div>
                            </div>
                        </li>
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/2.png" alt="" />
                                <div className="advantages-name">
                                    Wsparcie techniczne
                                </div>
                                <div className="advantages-text">
                                    Odpowiedzi na pytania klientów?
                                    Zabezpieczenie transakcji? Wszystko
                                    to jest po naszej stronie!
                                </div>
                            </div>
                        </li>
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/3.png" alt="" />
                                <div className="advantages-name">
                                    Szybka wymiana do 7 minut
                                </div>
                                <div className="advantages-text">
                                    Oferujemy błyskawiczne transakcje
                                    sprzedaży Bitcoin, Ethereum, Tether
                                    oraz innych kryptowalut.Szybko,
                                    łatwo i bezpiecznie, dzięki
                                    sprawdzonej platformie wymiany walut
                                    od 2019 roku.
                                </div>
                            </div>
                        </li>
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/4.png" alt="" />
                                <div className="advantages-name">
                                    Oszczędność
                                </div>
                                <div className="advantages-text">
                                    Nawet 8% tańszą wymianę niż w
                                    bankach i kantorach
                                </div>
                            </div>
                        </li>
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/5.png" alt="" />
                                <div className="advantages-name">
                                    Atrakcyjny kursy wymiany
                                    <div className="advantages-text">
                                        Oferujemy bardzo atrakcyjne i
                                        konkurencyjne kursy wymiany
                                        kryptowalut, które są
                                        aktualizowane w czasie
                                        rzeczywistym
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="advantages-item">
                            <div className="advantages-wrap">
                                <img src="img/6.png" alt="" />
                                <div className="advantages-name">
                                    Łatwość
                                </div>
                                <div className="advantages-text">
                                    Określ ile kryptowaluty chcesz
                                    wymienić, dzięki specjalnemu
                                    narzędziu, które dokładnie przeliczy
                                    na kryptowaluty złotówki, a
                                    następnie dokonaj przelewu
                                    internetowego. Obsługujemy większość
                                    środków płatniczych, a transakcje
                                    płatnicze są szyfrowane poprzez SSL.
                                    Bezpieczeństwo transakcji
                                    porównywalne jest do operacji
                                    bankowych!.
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="video-wrap">
                        <div className="video-bg">
                            <svg
                                width={104}
                                height={71}
                                viewBox="0 0 104 71"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="jss152"
                            >
                                <path
                                    id="button"
                                    d="M99.58 6.84182C96.759 1.85891 93.6975 0.942364 87.464 0.593818C81.237 0.174273 65.5785 0 52.013 0C38.4215 0 22.7565 0.174273 16.536 0.587364C10.3155 0.942364 7.2475 1.85245 4.4005 6.84182C1.495 11.8183 0 20.3899 0 35.4806C0 35.4935 0 35.5 0 35.5C0 35.5129 0 35.5194 0 35.5194V35.5323C0 50.5585 1.495 59.1946 4.4005 64.1195C7.2475 69.1024 10.309 70.006 16.5295 70.4255C22.7565 70.787 38.4215 71 52.013 71C65.5785 71 81.237 70.787 87.4705 70.432C93.704 70.0125 96.7655 69.1088 99.5865 64.1259C102.518 59.2011 104 50.5649 104 35.5387C104 35.5387 104 35.5194 104 35.5065C104 35.5065 104 35.4935 104 35.4871C104 20.3899 102.518 11.8183 99.58 6.84182Z"
                                    fill="black"
                                    fillOpacity="0.7"
                                />
                                <path
                                    d="M71.4127 33.2558C72.7744 34.0199 72.7744 35.9801 71.4127 36.7442L42.0016 53.2472C40.6684 53.9953 39.0229 53.0317 39.0229 51.503L39.0229 18.497C39.0229 16.9683 40.6684 16.0047 42.0016 16.7528L71.4127 33.2558Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        {/*                    <iframe class="video" src="https://www.youtube.com/embed/qJeGwT_bZbE?autoplay=0&amp;loop=1&amp;rel=0&amp;disablekb=1&amp;modestbranding=1&amp;iv_load_policy=3&amp;fs=0&amp;showinfo=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>*/}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Step1BG;